import { ColumnDef } from '@tanstack/react-table';
import { CustomTable } from 'components/Table/CustomTable';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { Fragment, useMemo, useState } from 'react';

import { ControlledModal } from 'components/ControlledModal';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import {
  EMediaSequenceState,
  EPermission,
  ETranscriptionState,
  MediaSequenceResponse,
  MediaSequenceResponsePagedData,
} from 'api/core';
import { LinkCell } from 'components/Table/Cell/LinkCell';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import {
  useDeleteMediaSequence,
  useGetMediaSequence,
} from 'api/useMediaSequencesApi';
import { BadgeCell } from 'components/Table/Cell/BadgeCell';
import { ProgressCell } from 'components/Table/Cell/ProgressCell';
import { Tooltip } from 'components/Tooltip';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { mediaSequenceStateTranslate } from 'utils/enum-translate';
import { MediaSequenceCreate } from './MediaSequenceCreate';

interface TableProps {
  data?: MediaSequenceResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const MediaSequencesTable = ({
  data,
  isFetching,
  queryState,
}: TableProps) => {
  const [transcriptionTarget, setAudioTranscriptionTarget] =
    useState<MediaSequenceResponse | null>(null);
  const [deleteTarget, setDeleteTarget] =
    useState<MediaSequenceResponse | null>(null);
  const [copyTarget, setCopyTarget] = useState<MediaSequenceResponse | null>(
    null
  );

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<MediaSequenceResponse>[] = [
      {
        accessorKey: '_view',
        header: 'Vis',
        enableSorting: false,
        cell: (e) => (
          <LinkCell
            icon={<AnimatedIcon icon="open-icon" className="h-6 w-6" />}
            path={`/media-sequences/${e.row.original.id}`}
          />
        ),
      },
      {
        accessorKey: 'name',
        header: 'Adresse',
      },
      {
        accessorKey: 'state',
        header: 'Status',
        cell: (e) =>
          e.row.original.state === EMediaSequenceState.Processing ? (
            <Tooltip
              className="flex"
              tooltip={`Færdig med ${e.row.original.mutationsCompleted} ud af ${e.row.original.mutationsRegistered} effekt(er).`}
            >
              <ProgressCell
                val={e.row.original.mutationsCompleted}
                maxVal={e.row.original.mutationsRegistered}
                paused={
                  e.row.original.transcriptionState ===
                  ETranscriptionState.Generated
                }
              />
            </Tooltip>
          ) : (
            <BadgeCell
              text={mediaSequenceStateTranslate(e.row.original.state)}
              type={
                e.row.original.state === EMediaSequenceState.Failed
                  ? 'error'
                  : 'success'
              }
            />
          ),
      },
      {
        accessorKey: 'transcription',
        header: 'Undertekster',
        enableSorting: false,
        cell: (e) => (
          <div>
            {e.row.original.transcriptionState ===
              ETranscriptionState.Approved &&
            e.row.original.transcription !== '' ? (
              <ActionCell
                icon={<AnimatedIcon icon="zoom-icon" className="h-6 w-6" />}
                onClick={() => setAudioTranscriptionTarget(e.row.original)}
              />
            ) : null}
            {e.row.original.transcriptionState ===
            ETranscriptionState.Generated ? (
              <LinkCell
                icon={
                  <div className="flex space-x-1 items-center">
                    <AnimatedIcon icon="write-icon" className="h-6 w-6" />
                    <span className="bg-info rounded-full w-3 h-3"></span>
                  </div>
                }
                path={`/media-sequences/${e.row.original.id}`}
                tooltip="Underteksterne er nu genereret og venter på din godkendelse. Klik her for at gå til godkendelse."
              />
            ) : null}
            {e.row.original.transcriptionState ===
            ETranscriptionState.Pending ? (
              <AnimatedIcon
                icon="loading-icon"
                autoPlay
                loop
                className="h-6 w-6"
              />
            ) : null}
          </div>
        ),
      },
      // {
      //   accessorKey: '_case.address',
      //   header: 'Sag',
      //   cell: (e) => e.row.original._case?.address,
      //   enableSorting: false,
      // },
      // {
      //   accessorKey: '_case.project.name',
      //   header: 'Projekt',
      //   cell: (e) => e.row.original._case?.project?.name,
      //   enableSorting: false,
      // },
      // {
      //   accessorKey: 'assets.length',
      //   header: 'Filer',
      //   enableSorting: false,
      // },
      // {
      //   accessorKey: 'branches.length',
      //   header: 'Formater',
      //   enableSorting: false,
      // },
      // {
      //   accessorKey: 'lastModifiedUtc',
      //   header: 'Sidst redigeret',
      //   cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      // },
      {
        accessorKey: 'user.name',
        header: 'Bruger',
        enableSorting: false,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <>
            <ActionCell
              permissions={[EPermission.MediaSequenceWrite]}
              icon={<AnimatedIcon icon="copy-icon" className="h-6 w-6" />}
              onClick={() => setCopyTarget(e.row.original)}
            />
            {e.row.original.state !== EMediaSequenceState.Processing ? (
              <ActionCell
                permissions={[EPermission.MediaSequenceDelete]}
                icon={
                  <AnimatedIcon icon="trash-icon" className="h-6 w-6 ml-2" />
                }
                onClick={() => setDeleteTarget(e.row.original)}
              />
            ) : null}
          </>
        ),
      },
    ];
    return baseColumns;
  }, [setAudioTranscriptionTarget, setDeleteTarget, setCopyTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetMediaSequence}
          deleter={useDeleteMediaSequence}
        />
      ) : null}
      {copyTarget ? (
        <MediaSequenceCreate
          existingMediaSequence={copyTarget}
          isInitialOpen={true}
          onClosed={() => setCopyTarget(null)}
        />
      ) : null}
      {transcriptionTarget && transcriptionTarget.transcription ? (
        <ControlledModal
          showModal={() => setAudioTranscriptionTarget(null)}
          isOpen={transcriptionTarget !== null}
          title={`Undertekster`}
          closeOnOutsideClick
          hideActionBar
        >
          <div className="pt-4">
            {transcriptionTarget.transcription
              .split('\n')
              .map((line, index) => (
                <Fragment key={index}>
                  {line}
                  <br />
                </Fragment>
              ))}
          </div>
        </ControlledModal>
      ) : null}
    </>
  );
};
