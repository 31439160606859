import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { LoginView } from './pages/Login/LoginView';
import { PermissionProtectedRoute } from 'auth/PermissionProtectedRoute';
import { AuditLogsView } from 'pages/Administration/AuditLogsView';
import { LanguagesView } from 'pages/Administration/Configuration/LanguagesView';
import { DashboardView } from 'pages/Dashboard/DashboardView';
import { LanguageView } from 'pages/Administration/Configuration/LanguageView';
import { EPermission } from 'api/core';
import { ProjectSettings } from 'pages/Project/ProjectSettings';
import { ProjectCases } from 'pages/Project/ProjectCases';
import { ProjectFields } from 'pages/Project/ProjectFields';
import { OrganizationUsers } from 'pages/Organization/OrganizationUsers';
import { OrganizationSettings } from 'pages/Organization/OrganizationSettings';
import { ProjectAssets } from 'pages/Project/ProjectAssets';
import { MediaSequences } from 'pages/MediaOrbit/MediaSequences';
import { MediaSequenceSettings } from 'pages/MediaOrbit/MediaSequenceSettings';
import { Projects } from 'pages/Project/Projects';
import { ProfileView } from 'pages/Profile/ProfileView';
import { OrganizationsView } from 'pages/Administration/OrganizationsView';
import { VisualIdentitesView } from 'pages/Administration/VisualIdentitiesView';
import { OrganizationAssets } from 'pages/Organization/OrganizationAssets';
import { ServiceTickets } from 'pages/Organization/ServiceTickets';
import { ServiceTicket } from 'pages/Organization/ServiceTicket';
import { ProjectCase } from 'pages/Project/ProjectCase';
import { ForgotPasswordView } from 'pages/Login/ForgotPasswordView';
import { ResetPasswordView } from 'pages/Login/ResetPasswordView';
import { RegisterView } from 'pages/Login/RegisterView';
import { MediaSequenceSetup } from 'pages/MediaOrbit/MediaSequenceSetup';
import { ProjectEstateOrbitPreview } from 'pages/Project/ProjectEstateOrbitPreview';
import { ProjectSections } from 'pages/Project/ProjectSections';
import { ProjectAngles } from 'pages/Project/ProjectAngles';
import { MediaSequenceTimelineView } from 'pages/MediaOrbit/MediaSequenceTimelineView';
import { Publications } from 'pages/Publications/Publications';
import { OrganizationSocialMedia } from 'pages/Organization/OrganizationSocialMedia';
import { OrganizationSessions } from 'pages/Organization/OrganizationSessions';
import { DynamicTemplatesView } from 'pages/Administration/DynamicTemplatesView';
import { ProjectStatistics } from 'pages/Project/ProjectStatistics';

export const loginRoutes: RouteObject[] = [
  {
    path: '',
    element: <LoginView />,
  },
  {
    path: 'register',
    children: [
      {
        path: ':passwordKey',
        element: <RegisterView />,
      },
    ],
  },
  {
    path: 'reset-password',
    children: [
      {
        path: '',
        element: <ForgotPasswordView />,
      },
      {
        path: ':resetKey',
        element: <ResetPasswordView />,
      },
    ],
  },
];

export const publicRoutes: RouteObject[] = [
  /*{
    path: 'terms-and-conditions',
    element: <TermsAndCoditionsView />,
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicyView />,
  },*/
];

// TODO: Permission overhaul
export const authenticatedRoutes: RouteObject[] = [
  {
    path: '',
    element: <DashboardView />,
  },
  {
    path: 'profile',
    children: [{ path: '', element: <ProfileView /> }],
  },
  {
    path: 'projects',
    element: (
      <PermissionProtectedRoute permissions={[EPermission.ProjectRead]}>
        <Outlet />
      </PermissionProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Projects />,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            element: <Navigate to="settings" replace />,
          },
          { path: 'case', element: <ProjectCase /> },
          { path: 'cases', element: <ProjectCases /> },
          { path: 'fields', element: <ProjectFields /> },
          { path: 'assets', element: <ProjectAssets /> },
          { path: 'statistics', element: <ProjectStatistics /> },
          { path: 'settings', element: <ProjectSettings /> },
          {
            path: 'estate-orbit',
            element: (
              <PermissionProtectedRoute
                permissions={[EPermission.ModuleBoligvaelger]}
              >
                <Outlet />
              </PermissionProtectedRoute>
            ),
            children: [
              {
                path: '',
                element: <Navigate to="preview" replace />,
              },
              { path: 'preview', element: <ProjectEstateOrbitPreview /> },
              { path: 'sections', element: <ProjectSections /> },
              { path: 'angles', element: <ProjectAngles /> },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'media-sequences',
    element: (
      <PermissionProtectedRoute
        permissions={[
          EPermission.ModuleMediaOrbit,
          EPermission.MediaSequenceRead,
        ]}
      >
        <Outlet />
      </PermissionProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <MediaSequences />,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            element: <Navigate to="setup" replace />,
          },
          {
            path: 'setup',
            children: [
              {
                path: '',
                element: <MediaSequenceSetup />,
              },
              {
                path: ':step',
                element: <MediaSequenceSetup />,
              },
            ],
          },
          { path: 'events', element: <MediaSequenceTimelineView /> },
          { path: 'settings', element: <MediaSequenceSettings /> },
        ],
      },
    ],
  },
  {
    path: 'publications',
    element: (
      <PermissionProtectedRoute
        permissions={[
          EPermission.ModuleSocialMedia,
          EPermission.PublicationRead,
        ]}
      >
        <Outlet />
      </PermissionProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Publications />,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            element: <MediaSequenceSetup />,
          },
        ],
      },
    ],
  },
  {
    path: 'organization',
    element: (
      <PermissionProtectedRoute permissions={[EPermission.ProjectRead]}>
        <Outlet />
      </PermissionProtectedRoute>
    ),
    children: [
      { path: 'users', element: <OrganizationUsers /> },
      { path: 'assets', element: <OrganizationAssets /> },
      {
        path: 'sessions',
        element: (
          <PermissionProtectedRoute
            permissions={[EPermission.OrganizationSeeSessionStatistics]}
          >
            <OrganizationSessions />
          </PermissionProtectedRoute>
        ),
      },
      { path: 'social-media', element: <OrganizationSocialMedia /> },
      {
        path: 'service-tickets',
        element: (
          <PermissionProtectedRoute
            permissions={[EPermission.ServiceTicketRead]}
          >
            <Outlet />
          </PermissionProtectedRoute>
        ),
        children: [
          {
            path: '',
            element: <ServiceTickets />,
          },
          {
            path: ':id',
            element: <ServiceTicket />,
          },
        ],
      },
      { path: 'settings', element: <OrganizationSettings /> },
    ],
  },
  {
    path: 'admin',
    element: (
      <PermissionProtectedRoute permissions={[EPermission.UserRead]}>
        <Outlet />
      </PermissionProtectedRoute>
    ),
    children: [
      {
        path: 'config',
        children: [
          {
            path: 'languages',
            children: [
              {
                path: '',
                element: <LanguagesView />,
              },
              { path: ':id', element: <LanguageView /> },
            ],
          },
        ],
      },
      {
        path: 'audit-logs',
        element: (
          <PermissionProtectedRoute permissions={[EPermission.AuditLogRead]}>
            <AuditLogsView />
          </PermissionProtectedRoute>
        ),
      },
      {
        path: 'organizations',
        element: (
          <PermissionProtectedRoute
            permissions={[EPermission.OrganizationRead]}
          >
            <OrganizationsView />
          </PermissionProtectedRoute>
        ),
      },
      {
        path: 'visual-identities',
        element: (
          <PermissionProtectedRoute
            permissions={[EPermission.OrganizationRead]}
          >
            <VisualIdentitesView />
          </PermissionProtectedRoute>
        ),
      },
      {
        path: 'dynamic-templates',
        element: (
          <PermissionProtectedRoute
            permissions={[EPermission.DynamicTemplateRead]}
          >
            <DynamicTemplatesView />
          </PermissionProtectedRoute>
        ),
      },
    ],
  },
];
