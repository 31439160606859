import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { MediaSequenceBranchMutationForm } from './MediaSequenceBranchMutationForm';
import { MediaSequenceResponse } from 'api/core';

interface MediaSequenceBranchMutationCreateProps {
  mediaSequence: MediaSequenceResponse;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
  disabled?: boolean;
}

export const MediaSequenceBranchMutationCreate = ({
  mediaSequence,
  trigger,
  isInitialOpen,
  onClosed,
  disabled,
}: MediaSequenceBranchMutationCreateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  if (disabled) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Tilføj effekt"
        closeOnOutsideClick
        hideActionBar
        className="w-auto max-w-full"
      >
        <MediaSequenceBranchMutationForm
          mediaSequence={mediaSequence}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
          disabled={disabled}
        />
      </ControlledModal>
    </>
  );
};
