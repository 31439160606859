import { useAuth } from 'auth/AuthProvider';
import { Page } from 'layouts/Admin/Page';

export const ProfileView = () => {
  const { user } = useAuth();

  return (
    <Page title="Profil">
      <div className="space-y-2">
        <div className="mockup-code">
          <pre className="ml-4">
            <code>{JSON.stringify(user, null, 3)}</code>
          </pre>
        </div>
      </div>
    </Page>
  );
};
