import {
  EMediaSequenceState,
  EPermission,
  MediaSequenceResponse,
} from 'api/core';
import {
  useGetMediaSequence,
  useInvokeMediaSequence,
  useRedoMediaSequence,
} from 'api/useMediaSequencesApi';
import { useAuth } from 'auth/AuthProvider';
import { MutateEntity } from 'components/Form/MutateEntity';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { MediaSequenceBranchesTable } from 'pages/MediaOrbit/components/MediaSequenceBranch/MediaSequenceBranchesTable';
import { useCallback, useEffect, useState } from 'react';
import { copyLinkToClipboard } from 'utils/clipboard';
import { useMediaSequenceSetup } from './MediaSequenceSetupProvider';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';

interface MediaSequenceSetupResultProps {
  mediaSequence: MediaSequenceResponse;
  disabled: boolean;
}

export const MediaSequenceSetupResult = ({
  mediaSequence,
  disabled,
}: MediaSequenceSetupResultProps) => {
  const { handlePrevious, handleNext } = useMediaSequenceSetup();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableText, setDisableText] = useState<string>('');

  const { data } = useGetMediaSequence(mediaSequence.id, {
    refetchInterval:
      mediaSequence.state === EMediaSequenceState.Processing ? 5000 : undefined,
  });

  const getEmailLink = () => {
    if (!user || !data) return '';
    const subject = `Smart Video færdig: ${data.name}`;
    const newLine = '%0D%0A';

    const baseLinkUrl = `${process.env.REACT_APP_CORE_API_URL}/download`;
    const branchLinks = data.branches.map((branch) => {
      const branchUrl = `${baseLinkUrl}/${branch.asset?.url.split('/').pop()}`;
      return `${branch.name}: ${branchUrl}`;
    });

    const body = `Hej${newLine}${newLine}Smart Videoen til ${data.name} er nu færdig, de forskellige formater kan downloades her:${newLine}${newLine}${branchLinks.join(
      newLine
    )}${newLine}${newLine}Mvh${newLine}${user.name}`;
    return `mailto:?subject=${subject}&body=${body}`;
  };

  const onRevalidateStep = useCallback(() => {
    if (mediaSequence.state !== EMediaSequenceState.Processed) {
      setDisableNext(true);
      setDisableText('Alle resultater skal først være færdige');
    } else {
      setDisableNext(false);
      setDisableText('');
    }
  }, [mediaSequence.state]);

  useEffect(() => {
    onRevalidateStep();
  }, [mediaSequence, onRevalidateStep]);

  if (!data) return null;

  return (
    <>
      <div className="space-y-4">
        {data.state === EMediaSequenceState.Draft ? (
          <div className="space-y-4">
            <p>
              Når du trykker på knappen nedenfor vil Smart Video starte med at
              lave sammenklippe din video. Det betyder at du ikke kan ændre i
              videoen efterfølgende, så sørg for at alt er som det skal være før
              du trykker på knappen.
            </p>
            <MutateEntity
              trigger={
                <button
                  id="results-finalize"
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  Færdiggør video
                </button>
              }
              title={`Start ${data.name} video?`}
              description={`Er du sikker på du vil færdiggøre videoen '${mediaSequence.name}'? Du kan ikke ændre i den efterfølgende.`}
              setter={useInvokeMediaSequence}
              mutationVariables={{ id: data.id }}
              onMutate={() => setIsLoading(false)}
              onMutateConfirm={() => setIsLoading(true)}
              hidden={disabled}
            />
          </div>
        ) : null}
        {mediaSequence.state === EMediaSequenceState.Processing ? (
          <>
            <div className="flex flex-col items-center space-y-4">
              <AnimatedIcon
                icon="movie-edit-icon"
                autoPlay
                loop
                className="h-18 w-18 md:h-32 md:w-32"
              />
              <p>
                Færdig med {data.mutationsCompleted} ud af{' '}
                {data.mutationsRegistered} effekt(er).
              </p>
              <progress
                className={`progress progress-accent`}
                value={data.mutationsCompleted}
                max={data.mutationsRegistered}
              />
            </div>
          </>
        ) : null}
        {mediaSequence.state === EMediaSequenceState.Processed ? (
          <>
            <div className="flex justify-start w-full space-x-2">
              <div className="dropdown dropdown-hover">
                <div tabIndex={0} role="button" className="btn btn-primary">
                  Del resultat
                </div>
                <ul
                  tabIndex={0}
                  className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                >
                  <li>
                    <a onClick={copyLinkToClipboard}>Kopier link</a>
                    <a href={getEmailLink()} target="_blank" rel="noreferrer">
                      Del via email
                    </a>
                  </li>
                </ul>
              </div>
              <MutateEntity
                trigger={
                  <button type="button" className="btn btn-warning">
                    Lav video om
                  </button>
                }
                title={`Lav videoen ${mediaSequence.name} om?`}
                description={`Er du sikker på du vil lave videoen '${mediaSequence.name}' om? Din opsætning vil blive gemt men resultaterne vil blive slettet.`}
                setter={useRedoMediaSequence}
                mutationVariables={{ id: mediaSequence.id }}
              />
            </div>
            <div id="results-overview">
              <MediaSequenceBranchesTable
                data={data}
                isFetching={false}
                disabled={disabled}
                showResult
              />
            </div>
          </>
        ) : null}
      </div>

      <div className="flex flex-col mt-4 mb-8">
        <div id="step-navigation" className="join justify-center mt-2">
          <button className="join-item btn" onClick={handlePrevious}>
            Forrige trin
          </button>
          <PermissionProtectedComponent
            permissions={[EPermission.ModuleSocialMedia]}
          >
            <button
              className="join-item btn"
              onClick={handleNext}
              disabled={disableNext}
            >
              Næste trin
            </button>
          </PermissionProtectedComponent>
        </div>
        <PermissionProtectedComponent
          permissions={[EPermission.ModuleSocialMedia]}
        >
          {disableNext && disableText ? (
            <div className="text-center mt-2">
              <p className="text-sm text-gray-500">{disableText}</p>
            </div>
          ) : null}
        </PermissionProtectedComponent>
      </div>
    </>
  );
};
