import {
  useBeginCreateAsset,
  useFinalizeCreateAsset,
  useUploadPresignedFileUrl,
} from 'api/useAssetsApi';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { ReactNode, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface AssetDragAndDropUploadContainerProps {
  projectId?: string;
  children: ReactNode;
}

export const AssetDragAndDropUploadContainer = ({
  projectId,
  children,
}: AssetDragAndDropUploadContainerProps) => {
  const { mutateAsync: createAsync } = useBeginCreateAsset();
  const { mutateAsync: uploadFileAsync } = useUploadPresignedFileUrl();
  const { mutateAsync: markAsync } = useFinalizeCreateAsset();

  const [isUploading, setIsUploading] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(0);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop: async (files) => {
      setFilesCount(files.length);
      setFilesUploaded(1);
      for (const file of files) {
        await handleFileUpload(file);
        setFilesUploaded((prev) => prev + 1);
      }
    },
  });

  const handleFileUpload = async (file: File) => {
    setIsUploading(true);
    const sha256 = await calculateChecksum(file);
    const assetResponse = await createAsync({
      assetCreateRequest: {
        projectId,
        fileName: file.name,
        mimeType: file.type,
        fileSize: file.size,
        checksumSha256: sha256,
        source: 'UserUploaded',
      },
    });

    if (assetResponse.foundMatchingAsset) {
      setIsUploading(false);
      return;
    }

    await uploadFileAsync({
      url: assetResponse.presignedUploadUrl,
      file,
    });

    await markAsync({ id: assetResponse.id });
    setIsUploading(false);
  };

  const calculateChecksum = async (file: File) => {
    const buffer = await file.arrayBuffer();
    const digest = await crypto.subtle.digest('SHA-256', buffer);
    return hexString(digest);
  };

  const hexString = (buffer: ArrayBuffer) => {
    const byteArray = new Uint8Array(buffer);
    const hexCodes = [...byteArray].map((value) => {
      return value.toString(16).padStart(2, '0');
    });
    return hexCodes.join('');
  };

  return (
    <div {...getRootProps()} className="h-full">
      <input {...getInputProps()} className="hidden" />
      {isDragActive ? (
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-50 text-white flex items-center justify-center text-lg z-50">
          Slip filerne her
        </div>
      ) : null}
      {isUploading ? (
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-50 text-white flex items-center justify-center text-lg z-50">
          <div className="flex flex-col items-center">
            <AnimatedIcon
              icon="upload-icon"
              autoPlay
              loop
              className="h-64 w-64"
            />
            <h2 className="text-2xl">
              Uploader fil {filesUploaded} ud af {filesCount}...
            </h2>
          </div>
        </div>
      ) : null}
      {children}
    </div>
  );
};
