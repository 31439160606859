import { useMutation, useQuery } from '@tanstack/react-query';

import { CoreBaseConfiguration } from './BaseConfiguration';
import { queryClient } from 'App';
import { toast } from 'react-toastify';
import {
  VisualIdentitiesApi,
  VisualIdentityCreateRequest,
  VisualIdentityUpdateRequest,
} from './core';
import { TableQueryState } from 'components/Table/useTableQueryState';

const VisualIdentityApiKeys = {
  GET_VISUAL_IDENTITIES: 'visualIdentities',
  GET_VISUAL_IDENTITY: 'visualIdentity',
};

const client = new VisualIdentitiesApi(new CoreBaseConfiguration());

export const useGetVisualIdentities = (state?: TableQueryState) => {
  return useQuery({
    queryKey: [VisualIdentityApiKeys.GET_VISUAL_IDENTITIES, state],
    queryFn: () =>
      client.visualIdentitiesGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
  });
};

export const useGetVisualIdentity = (id?: string) => {
  return useQuery({
    queryKey: [VisualIdentityApiKeys.GET_VISUAL_IDENTITY, id],
    queryFn: () => client.visualIdentitiesIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateVisualIdentity = () => {
  return useMutation({
    mutationFn: ({
      visualIdentityCreateRequest,
    }: {
      visualIdentityCreateRequest: VisualIdentityCreateRequest;
    }) => client.visualIdentitiesPost({ visualIdentityCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [VisualIdentityApiKeys.GET_VISUAL_IDENTITIES],
      });
      toast.success(`Identiteten '${e.name}' er blevet oprettet`, {
        toastId: 'createVisualIdentity',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke oprette identiteten');
    },
  });
};

export const useUpdateVisualIdentity = () => {
  return useMutation({
    mutationFn: ({
      id,
      visualIdentityUpdateRequest,
    }: {
      id: string;
      visualIdentityUpdateRequest: VisualIdentityUpdateRequest;
    }) => client.visualIdentitiesIdPut({ id, visualIdentityUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [VisualIdentityApiKeys.GET_VISUAL_IDENTITIES],
      });
      queryClient.invalidateQueries({
        queryKey: [VisualIdentityApiKeys.GET_VISUAL_IDENTITY, e.id],
      });
      toast.success(`Identiteten '${e.name}' er blevet opdateret`, {
        toastId: 'updateVisualIdentity',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke opdatere identiteten');
    },
  });
};

export const useDeleteVisualIdentity = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.visualIdentitiesIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [VisualIdentityApiKeys.GET_VISUAL_IDENTITIES],
      });
      queryClient.invalidateQueries({
        queryKey: [VisualIdentityApiKeys.GET_VISUAL_IDENTITY, e.id],
      });
      toast.success(`Identiteten '${e.name}' er blevet slettet`, {
        toastId: 'deleteVisualIdentity',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke slette identiteten');
    },
  });
};
