export interface TemplateFormat {
  id: number;
  name: string;
  width: number;
  height: number;
}

export const BRANCH_TEMPLATE_FORMATS: TemplateFormat[] = [
  {
    id: 1,
    name: 'Facebook - Square',
    width: 1080,
    height: 1080,
  },
  {
    id: 2,
    name: 'Facebook - Landscape',
    width: 1280,
    height: 720,
  },
  {
    id: 3,
    name: 'Facebook - Portrait',
    width: 720,
    height: 1280,
  },
  {
    id: 4,
    name: 'Instagram - Square',
    width: 1080,
    height: 1080,
  },
  {
    id: 5,
    name: 'Instagram - Landscape',
    width: 1080,
    height: 566,
  },
  {
    id: 6,
    name: 'Instagram - Portrait',
    width: 1080,
    height: 1350,
  },
  {
    id: 7,
    name: 'Instagram - Stories',
    width: 1080,
    height: 1920,
  },
  {
    id: 8,
    name: 'Twitter - Landscape',
    width: 1280,
    height: 720,
  },
  {
    id: 9,
    name: 'Twitter - Portrait',
    width: 720,
    height: 1280,
  },
  {
    id: 10,
    name: 'YouTube',
    width: 1920,
    height: 1080,
  },
  {
    id: 11,
    name: 'TikTok',
    width: 1080,
    height: 1920,
  },
];
