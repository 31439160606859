import { ColumnDef } from '@tanstack/react-table';
import {
  EAssetMutationType,
  MediaSequenceAssetMutationResponse,
  MediaSequenceAssetResponse,
} from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';

import { useMemo, useState } from 'react';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { MediaSequenceAssetMutationUpdate } from './MediaSequenceAssetMutationUpdate';
import {
  assetMutationTranslate,
  imageToVideoModeTranslate,
  positionTranslate,
} from 'utils/enum-translate';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import {
  useDeleteMediaSequenceAssetMutation,
  useGetMediaSequenceAssetMutation,
} from 'api/useMediaSequenceAssetsApi';

interface TableProps {
  asset: MediaSequenceAssetResponse;
  projectId?: string;
  disabled?: boolean;
}

export const MediaSequenceAssetMutationsTable = ({
  asset,
  projectId,
  disabled,
}: TableProps) => {
  const [editTarget, setEditTarget] =
    useState<MediaSequenceAssetMutationResponse | null>(null);
  const [deleteTarget, setDeleteTarget] =
    useState<MediaSequenceAssetMutationResponse | null>(null);

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<MediaSequenceAssetMutationResponse>[] = [
      {
        accessorKey: 'type',
        header: 'Type',
        enableSorting: false,
        cell: (e) => assetMutationTranslate(e.row.original.type),
      },
      {
        accessorKey: 'description',
        header: 'Beskrivelse',
        enableSorting: false,
        cell: (e) => describeMutation(e.row.original),
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex float-right space-x-5 md:space-x-3">
            <ActionCell
              icon={
                <div id="assets-edit-asset-mutation">
                  <AnimatedIcon
                    icon={disabled ? 'zoom-icon' : 'pencil-icon'}
                    className="h-8 md:h-6 w-8 md:w-6"
                  />
                </div>
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            <ActionCell
              disabled={
                e.row.original.type === EAssetMutationType.ImageToVideo ||
                disabled
              }
              icon={
                <div id="assets-delete-asset-mutation">
                  <AnimatedIcon
                    icon="trash-icon"
                    className="h-8 md:h-6 w-8 md:w-6"
                  />
                </div>
              }
              onClick={() => setDeleteTarget(e.row.original)}
            />
          </div>
        ),
      },
    ];
    return baseColumns;
  }, [setEditTarget, setDeleteTarget, disabled]);

  const table = useGetCustomReactTable(asset.mutations, -1, columns, undefined);

  return (
    <>
      <CustomTable table={table} hidePagination hideHeader />
      {editTarget ? (
        <MediaSequenceAssetMutationUpdate
          id={editTarget?.id}
          projectId={projectId}
          asset={asset}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
          disabled={disabled}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          title="effekten"
          titlePropertyKey={'id'}
          getter={useGetMediaSequenceAssetMutation}
          deleter={useDeleteMediaSequenceAssetMutation}
        />
      ) : null}
    </>
  );
};

const describeMutation = (mutation: MediaSequenceAssetMutationResponse) => {
  const description = () => {
    switch (mutation.type) {
      case EAssetMutationType.ColorAdjustments:
        if (!mutation.colorAdjustments) return;
        return <div>{/* TODO */}</div>;
      case EAssetMutationType.TrimVideo:
        if (!mutation.trimVideo) return;
        return (
          <div>
            <p>Start: {mutation.trimVideo.trimStartSeconds}</p>
            <p>Slut: {mutation.trimVideo.trimEndSeconds}</p>
          </div>
        );
      case EAssetMutationType.ImageToVideo:
        if (!mutation.imageToVideo) return;
        return (
          <div>
            <p>{imageToVideoModeTranslate(mutation.imageToVideo.mode)}</p>
            <p>{mutation.imageToVideo.durationInSeconds} sekunder</p>
          </div>
        );
      case EAssetMutationType.ImageOverlay:
        if (!mutation.overlay) return;
        return (
          <div>
            {/* TODO: Maybe add name of the asset? */}
            <p>{positionTranslate(mutation.overlay.position)}</p>
          </div>
        );
      case EAssetMutationType.SoundEffect:
        if (!mutation.soundEffect) return;
        return <div>{/* TODO: Maybe add name of the asset? */}</div>;
    }
  };

  return description();
};
