import { ColumnDef } from '@tanstack/react-table';
import { CustomTable } from 'components/Table/CustomTable';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useMemo, useState } from 'react';

import { ControlledModal } from 'components/ControlledModal';
import { AuditLogResponse, AuditLogResponsePagedData } from 'api/core';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';

interface TableProps {
  data?: AuditLogResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const AuditLogsTable = ({
  data,
  isFetching,
  queryState,
}: TableProps) => {
  const [auditLogTarget, setAuditLogTarget] = useState<AuditLogResponse | null>(
    null
  );

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<AuditLogResponse>[] = [
      {
        accessorKey: 'id',
        header: '#',
      },
      {
        accessorKey: 'userId',
        header: 'Bruger ID',
      },
      {
        accessorKey: 'name',
        header: 'Navn',
        enableSorting: false,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: false,
      },
      {
        accessorKey: 'data',
        header: 'Log',
        enableSorting: false,
        cell: (e) => (
          <ActionCell
            icon={<AnimatedIcon icon="zoom-icon" className="h-6 w-6" />}
            onClick={() => setAuditLogTarget(e.row.original)}
          />
        ),
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
    ];
    return baseColumns;
  }, [setAuditLogTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {auditLogTarget ? (
        <ControlledModal
          showModal={() => setAuditLogTarget(null)}
          isOpen={auditLogTarget !== null}
          title={'Log'}
          closeOnOutsideClick
          hideActionBar
          className="max-w-3xl w-full"
        >
          <div className="pt-4">
            <div className="mockup-code">
              <pre className="pl-4">
                <code>
                  {JSON.stringify(JSON.parse(auditLogTarget.data), null, 2)}
                </code>
              </pre>
            </div>
          </div>
        </ControlledModal>
      ) : null}
    </>
  );
};
