import { useQuery } from '@tanstack/react-query';

import { CoreBaseConfiguration } from './BaseConfiguration';
import { StatsApi } from './core';

const StatsApiKeys = {
  GET_STATS: 'stats',
};

const client = new StatsApi(new CoreBaseConfiguration());

export const useGetStats = () => {
  return useQuery({
    queryKey: [StatsApiKeys.GET_STATS],
    queryFn: () => client.statsGet(),
  });
};
