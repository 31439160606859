import { AssetResponse, AssetUpdateRequest } from 'api/core';
import { useUpdateAsset } from 'api/useAssetsApi';
import { useForm } from 'react-hook-form';

interface AssetFormProps {
  targetAsset: AssetResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const AssetForm = ({
  targetAsset,
  onSuccess,
  onCancel,
}: AssetFormProps) => {
  const { mutateAsync, isPending } = useUpdateAsset();

  const { register, handleSubmit } = useForm<AssetUpdateRequest>({
    defaultValues: {
      fileName: targetAsset?.originalFileName,
    },
  });

  const onSubmit = handleSubmit(async (result) => {
    await mutateAsync({
      id: targetAsset.id,
      assetUpdateRequest: result,
    });
    onSuccess?.();
  });

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Filnavn</span>
        </label>
        <input
          {...register('fileName', { required: true })}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button className="btn btn-primary" disabled={isPending}>
          Opdater
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
