import { useQuery } from '@tanstack/react-query';

import { useAuth } from 'auth/AuthProvider';
import { CoreBaseConfiguration } from './BaseConfiguration';
import { EPermission, MetaMappingsApi } from './core';

export const MetaMappingsApiKeys = {
  GET_META_MAPPINGS: 'meta-mappings',
};

const client = new MetaMappingsApi(new CoreBaseConfiguration());

export const useGetMetaMappings = (mediaSequenceId?: string) => {
  const { hasPermissions } = useAuth();

  return useQuery({
    queryKey: [MetaMappingsApiKeys.GET_META_MAPPINGS, mediaSequenceId],
    queryFn: () =>
      client.metaMappingsIdGet({
        id: mediaSequenceId ?? '',
      }),
    enabled:
      mediaSequenceId !== undefined &&
      hasPermissions([EPermission.MediaSequenceMetaMappings]),
  });
};
