import { useEffect, useState } from 'react';
import {
  parseSRT,
  secondsToTime,
  setErrorMessages,
  stringifySRT,
  SubtitleSection,
  timeToSeconds,
} from './parser';

import { useDebounce } from 'utils/useDebounce';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { useAuth } from 'auth/AuthProvider';
import { EPermission } from 'api/core';
import { twMerge } from 'tailwind-merge';

interface SubtitleFormProps {
  subtitleString?: string;
  videoLengthInSeconds?: number;
  onChanged: (subtitleString: string) => void;
  onErrorsChanged: (hasErrors: boolean) => void;
  disabled?: boolean;
}

export const SubtitleForm = ({
  subtitleString,
  videoLengthInSeconds,
  onChanged,
  onErrorsChanged,
  disabled,
}: SubtitleFormProps) => {
  const { hasPermissions } = useAuth();
  const hasAdvancedEditor = hasPermissions([
    EPermission.MediaSequenceEditorTranscriptionAdvanced,
  ]);

  const setSectionsWithErrorMessage = (sections: SubtitleSection[]) => {
    setErrorMessages(sections, videoLengthInSeconds);

    const hasErrors = sections.some(
      (section) =>
        section.errors.text ||
        section.errors.startTime ||
        section.errors.endTime
    );
    onErrorsChanged(hasErrors);

    setSections(sections);
  };

  const [sections, setSections] = useState<SubtitleSection[]>(() => {
    const sections = subtitleString ? parseSRT(subtitleString) : [];
    setErrorMessages(sections, videoLengthInSeconds);
    return sections;
  });

  const debouncedSections = useDebounce(sections, 200);

  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedSections = sections.map((section, i) =>
      i === index ? { ...section, [field]: value } : section
    );
    setSectionsWithErrorMessage(updatedSections);
  };

  const handleAddSection = (index: number) => {
    const previousSection = sections[index];
    const nextSection = sections[index + 1];
    const newSection: SubtitleSection = {
      index: (sections.length + 1).toString(),
      startTime: previousSection.endTime,
      endTime: nextSection
        ? nextSection.startTime
        : secondsToTime(timeToSeconds(previousSection.endTime) + 1),
      text: '',
      errors: {},
    };
    const updatedSections = [
      ...sections.slice(0, index + 1),
      newSection,
      ...sections.slice(index + 1),
    ];
    setSectionsWithErrorMessage(updatedSections);
  };

  const handleRemoveSection = (index: number) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    setSectionsWithErrorMessage(updatedSections);
  };

  useEffect(() => {
    const stringified = stringifySRT(debouncedSections);
    if (stringified !== subtitleString && stringified !== null) {
      onChanged(stringified);
    }
  }, [debouncedSections, onChanged, subtitleString]);

  return (
    <div
      id="subtitles-edit-subtitles-area"
      className="space-y-2 overflow-auto"
      style={{ maxHeight: '450px' }}
    >
      {sections.map((section, index) => (
        <div key={section.index} className="border p-2 rounded-md">
          {!disabled && hasAdvancedEditor ? (
            <div className="flex space-x-5 md:space-x-3 justify-end">
              <ActionCell
                icon={<AnimatedIcon icon="plus-icon" className="h-6 w-6" />}
                onClick={() => handleAddSection(index)}
                tooltip="Tilføj nyt segment under"
                position="left"
              />
              <ActionCell
                icon={<AnimatedIcon icon="trash-icon" className="h-6 w-6" />}
                onClick={() => handleRemoveSection(index)}
                tooltip="Slet segment"
                position="left"
              />
            </div>
          ) : null}
          <div className="mb-2 xl:flex xl:space-x-4">
            <div className="xl:flex-1">
              <label className="label">
                <span className="label-text">Indhold</span>
              </label>
              <textarea
                className={twMerge(
                  'textarea textarea-bordered w-full',
                  section.errors.text && 'textarea-error'
                )}
                rows={1}
                title={section.errors.startTime}
                value={section.text}
                onChange={(e) =>
                  handleInputChange(index, 'text', e.target.value)
                }
                disabled={disabled}
              />
            </div>
            <div className="xl:flex-1 flex space-x-4">
              <div className="w-1/2">
                <label className="label">
                  <span className="label-text">Start</span>
                </label>
                <input
                  type="number"
                  title={section.errors.startTime}
                  className={twMerge(
                    'input input-bordered w-full',
                    section.errors.startTime && 'input-error'
                  )}
                  value={timeToSeconds(section.startTime)}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'startTime',
                      secondsToTime(parseFloat(e.target.value))
                    )
                  }
                  min={0}
                  max={videoLengthInSeconds}
                  step="0.01"
                  disabled={disabled || !hasAdvancedEditor}
                />
              </div>
              <div className="w-1/2">
                <label className="label">
                  <span className="label-text">Slut</span>
                </label>
                <input
                  type="number"
                  title={section.errors.endTime}
                  className={twMerge(
                    'input input-bordered w-full',
                    section.errors.endTime && 'input-error'
                  )}
                  value={timeToSeconds(section.endTime)}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'endTime',
                      secondsToTime(parseFloat(e.target.value))
                    )
                  }
                  step="0.01"
                  min={Math.max(0, timeToSeconds(section.startTime))}
                  max={
                    videoLengthInSeconds ? videoLengthInSeconds + 10 : undefined
                  }
                  disabled={disabled || !hasAdvancedEditor}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
