import { useGetProject } from 'api/useProjectsApi';
import { Page } from 'layouts/Admin/Page';
import { useParams } from 'react-router-dom';
import { AnglesTable } from './components/Angle/AnglesTable';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { AngleCreate } from './components/Angle/AngleCreate';
import { useGetAngles } from 'api/useAnglesApi';

export const ProjectAngles = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useTableQueryState();

  const { data: project } = useGetProject(id, true);
  const { data: fields, isFetching: isFetchingAngles } = useGetAngles(
    id,
    state
  );

  return (
    <Page
      title={project?.name}
      breadcrumbs={[
        { name: 'Projekter' },
        { name: project?.name ?? '' },
        { name: 'Estate Orbit' },
        { name: 'Vinkler' },
      ]}
    >
      <AngleCreate
        projectId={id as string}
        trigger={
          <div className="flex justify-start py-4 w-full">
            <button className="btn btn-primary mr-2">Opret vinkel</button>
          </div>
        }
      />
      <AnglesTable
        projectId={id as string}
        data={fields}
        isFetching={isFetchingAngles}
        queryState={state}
      />
    </Page>
  );
};
