import { MediaSequenceAssetSortableGrid } from 'pages/MediaOrbit/components/MediaSequenceAsset/MediaSequenceAssetSortableGrid';
import { MediaSequenceResponse } from 'api/core';

interface MediaSequenceSetupAssetsProps {
  mediaSequence: MediaSequenceResponse;
  disabled: boolean;
}

export const MediaSequenceSetupAssets = ({
  mediaSequence,
  disabled,
}: MediaSequenceSetupAssetsProps) => {
  return (
    <div>
      <MediaSequenceAssetSortableGrid
        mediaSequenceId={mediaSequence.id}
        projectId={mediaSequence._case?.project.id}
        disabled={disabled}
      />
    </div>
  );
};
