import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'App';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  AngleCreateRequest,
  AngleUpdateRequest,
  EstateOrbitAnglesApi,
} from './core';

const AngleApiKeys = {
  GET_SECTIONS: 'angles',
  GET_SECTION: 'angle',
};

const client = new EstateOrbitAnglesApi(new CoreBaseConfiguration());

export const useGetAngles = (projectId?: string, state?: TableQueryState) => {
  return useQuery({
    queryKey: [AngleApiKeys.GET_SECTIONS, projectId, state],
    queryFn: () =>
      client.estateOrbitProjectsAnglesGet({
        projectId,
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id || 'order',
        ascending: !state?.sorting?.length
          ? true
          : state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
    enabled: projectId !== undefined,
  });
};

export const useGetAngle = (id?: string) => {
  return useQuery({
    queryKey: [AngleApiKeys.GET_SECTION, id],
    queryFn: () => client.estateOrbitProjectsAnglesIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateAngle = () => {
  return useMutation({
    mutationFn: ({
      angleCreateRequest,
    }: {
      angleCreateRequest: AngleCreateRequest;
    }) =>
      client.estateOrbitProjectsAnglesPost({
        angleCreateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [AngleApiKeys.GET_SECTIONS],
      });
      toast.success(`Vinklen '${e.name}' er blevet oprettet`, {
        toastId: 'createAngle',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke oprette vinklen');
    },
  });
};

export const useUpdateAngle = () => {
  return useMutation({
    mutationFn: ({
      id,
      angleUpdateRequest,
    }: {
      id: string;
      angleUpdateRequest: AngleUpdateRequest;
    }) => client.estateOrbitProjectsAnglesIdPut({ id, angleUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [AngleApiKeys.GET_SECTIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [AngleApiKeys.GET_SECTION, e.id],
      });
      toast.success(`Vinklen '${e.name}' er blevet opdateret`, {
        toastId: 'updateAngle',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke opdatere vinklen');
    },
  });
};

export const useDeleteAngle = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.estateOrbitProjectsAnglesIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [AngleApiKeys.GET_SECTIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [AngleApiKeys.GET_SECTION, e.id],
      });
      toast.success(`Vinklen '${e.name}' er blevet slettet`, {
        toastId: 'deleteAngle',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke slette vinklen');
    },
  });
};
