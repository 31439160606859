import { EResizeMode } from 'api/core';

interface VideoResizeModePreviewProps {
  mode: EResizeMode;
  assetUrl: string;
  outputWidth: number;
  outputHeight: number;
}

export const VideoResizeModePreview = ({
  mode,
  assetUrl,
  outputWidth,
  outputHeight,
}: VideoResizeModePreviewProps) => {
  const renderModeExample = () => {
    switch (mode) {
      case 'Crop':
        return (
          <div>
            <div
              className="w-full"
              style={{ aspectRatio: `${outputWidth} / ${outputHeight}` }}
            >
              <video
                autoPlay
                loop
                muted
                className="w-full h-full object-cover"
                key={'Crop'}
              >
                <source src={assetUrl} />
              </video>
            </div>
            <ul className="mt-4">
              <li>
                <b>Beskrivelse</b>: Billedet skæres til, så det passer med et
                kvadratisk format.
              </li>
              <li>
                <b>Fordele</b>: Bevarer billedets naturlige proportioner inden
                for det kvadratiske format.
              </li>
              <li>
                <b>Ulemper</b>: En del af toppen og bunden af billedet bliver
                skåret væk, hvilket kan medføre tab af vigtige detaljer.
              </li>
              <li>
                <b>Anbefaling</b>: Anbefales, hvis de afskårne dele af billedet
                ikke indeholder vigtige elementer.
              </li>
            </ul>
          </div>
        );
      case 'LetterboxPadding':
        return (
          <div>
            <div
              className="w-full bg-black "
              style={{ aspectRatio: `${outputWidth} / ${outputHeight}` }}
            >
              <video
                autoPlay
                loop
                muted
                className="w-full h-full"
                key={'LetterboxPadding'}
              >
                <source src={assetUrl} />
              </video>
            </div>
            <ul className="mt-4">
              <li>
                <b>Beskrivelse</b>: Tilføjer sorte kanter på siderne eller i
                toppen og bunden for at bevare det oprindelige aspect ratio.
              </li>
              <li>
                <b>Fordele</b>: Bevarer hele billedet uden forvrængning.
              </li>
              <li>
                <b>Ulemper</b>: De sorte kanter kan være visuelt distraherende
                og mindre æstetiske.
              </li>
              <li>
                <b>Anbefaling</b>: Anbefales, hvis det er vigtigt at undgå tab
                af elementer i billedet.
              </li>
            </ul>
          </div>
        );
      case 'CropWithBlurredPadding':
        return (
          <div>
            <div
              className="relative w-full overflow-hidden flex justify-center items-center bg-black"
              style={{ aspectRatio: `${outputWidth} / ${outputHeight}` }}
            >
              <video
                autoPlay
                loop
                muted
                key={'CropWithBlurredPaddingBackground'}
                className="absolute top-1/2 left-1/2 w-full h-auto z-10"
                style={{
                  transform: 'translate(-50%, -50%) scale(1.2)',
                  filter: 'blur(20px)',
                }}
              >
                <source src={assetUrl} />
              </video>

              <video
                autoPlay
                loop
                muted
                className="relative w-auto h-full z-20"
                key={'CropWithBlurredPaddingForeground'}
              >
                <source src={assetUrl} />
              </video>
            </div>
            <ul className="mt-4">
              <li>
                <b>Beskrivelse</b>: Tilføjer slørede kanter, der afspejler det
                oprindelige billede, i stedet for sorte kanter.
              </li>
              <li>
                <b>Fordele</b>: Bevarer hele billedet uden forvrængning og gør
                videoen mere livlig og integreret.
              </li>
              <li>
                <b>Ulemper</b>: Kan stadig distrahere lidt, men er generelt mere
                æstetisk tiltalende end sorte kanter.
              </li>
              <li>
                <b>Anbefaling</b>: En god metode, hvis der ønskes en lidt mere
                dynamisk præsentation uden tab af billedelementer.
              </li>
            </ul>
          </div>
        );
      case 'Forced':
        return (
          <div>
            <video
              autoPlay
              loop
              muted
              className="w-full h-full"
              key={'portrait-to-square-forced.mp4'}
            >
              <source src="/media-orbit/portrait-to-square-forced.mp4" />
            </video>
            <ul className="mt-4">
              <li>
                <b>Beskrivelse</b>: Billedet forvrænges for at passe til et
                kvadratisk format.
              </li>
              <li>
                <b>Fordele</b>: Ingen dele af billedet går tabt.
              </li>
              <li>
                <b>Ulemper</b>: Billedet bliver forvrænget og ser unaturligt ud.
              </li>
              <li>
                <b>Anbefaling</b>: Vi anbefaler som udgangspunkt ikke denne
                metode, da forvrængningen sjældent giver et tilfredsstillende
                resultat.
              </li>
            </ul>
          </div>
        );
      default:
        return <div>Der er ikke oprettet et eksempel på denne type endnu.</div>;
    }
  };

  return renderModeExample();
};
