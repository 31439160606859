import {
  CaseResponse,
  EPermission,
  MediaSequenceCreateRequest,
  MediaSequenceResponse,
  UserSimpleResponse,
} from 'api/core';
import { useSearchCases } from 'api/useCasesApi';
import {
  useCloneMediaSequence,
  useCreateMediaSequence,
  useSearchMediaSequences,
  useSearchMediaSequenceTemplates,
} from 'api/useMediaSequencesApi';
import { useSearchUsers } from 'api/useUsersApi';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import {
  AddressSuggestion,
  AutocompleteAddress,
} from 'components/Form/AutocompleteAddress';
import { LabelWithHelperText } from 'components/Form/LabelWithHelperText';
import { EntitySelectSingleAsync } from 'components/Select/EntitySelectSingleAsync';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface MediaSequenceCreateFormProps {
  existingMediaSequence?: MediaSequenceResponse;
  existingCase?: CaseResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
  inDialog?: boolean;
}

export const MediaSequenceCreateForm = ({
  existingMediaSequence,
  existingCase,
  onSuccess,
  onCancel,
  inDialog,
}: MediaSequenceCreateFormProps) => {
  const [isUsingTemplate, setIsUsingTemplate] = useState<boolean>(false);

  const [selectedMediaSequence, setSelectedMediaSequence] =
    useState<MediaSequenceResponse | null>(existingMediaSequence ?? null);

  const [selectedSuggestion, setSelectedSuggestion] =
    useState<AddressSuggestion>();

  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateMediaSequence();
  const { mutateAsync: cloneAsync, isPending: IsPendingClone } =
    useCloneMediaSequence();

  const { data: templates } = useSearchMediaSequenceTemplates();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { touchedFields },
  } = useForm<MediaSequenceCreateRequest>({
    defaultValues: {
      name: existingMediaSequence
        ? `${existingMediaSequence?.name} (kopi)`
        : undefined,
      caseId: existingCase?.id ?? null,
      metaMappingUserId: null,
    },
  });

  const onSubmit = handleSubmit(async (result) => {
    if (selectedSuggestion && selectedSuggestion.forslagstekst) {
      result.name = selectedSuggestion.forslagstekst;
    }

    if (selectedMediaSequence) {
      const cloneResult = await cloneAsync({
        id: selectedMediaSequence.id,
        mediaSequenceCloneRequest: result,
      });
      navigate(`/media-sequences/${cloneResult.id}`);
    } else {
      const mediaSequence = await mutateAsync({
        mediaSequenceCreateRequest: result,
      });
      navigate(`/media-sequences/${mediaSequence.id}`);
    }
    onSuccess?.();
  });

  const onCaseSelected = (caseResponse: CaseResponse | null) => {
    if (!touchedFields.name || getValues('name') === '') {
      setValue('name', `${caseResponse?.address} - Redigering`);
    }
    setValue('caseId', caseResponse?.id);
  };

  const onUserSelected = (userResponse: UserSimpleResponse | null) => {
    setValue('metaMappingUserId', userResponse?.id);
  };

  const onMediaSequenceSelected = (
    mediaSequenceResponse: MediaSequenceResponse | null
  ) => {
    if (
      (!touchedFields.name || getValues('name') === '') &&
      mediaSequenceResponse?.name
    ) {
      setValue('name', `${mediaSequenceResponse?.name} (kopi)`);
    }
    setSelectedMediaSequence(mediaSequenceResponse);
  };

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      {templates && templates.length > 0 ? (
        <div className="form-control">
          <LabelWithHelperText
            label="Skabelon"
            helperText="Du har mulighed for at vælge en skabelon som udgangspunkt for din nye video. Dette vil kopiere alle indstillinger fra den valgte skabelon."
            inDialog={inDialog}
          />
          <ul className="menu menu-md pl-0 menu-horizontal m-0 pt-0 gap-1 before:content-none">
            <li>
              <a
                className={twMerge(
                  'border border-gray-300',
                  !isUsingTemplate && 'active'
                )}
                onClick={() => {
                  setIsUsingTemplate(false);
                }}
              >
                Ingen skabelon
              </a>
            </li>
            {templates.map((template) => (
              <li key={template.id}>
                <a
                  className={twMerge(
                    'border border-gray-300',
                    isUsingTemplate &&
                      selectedMediaSequence?.id === template.id &&
                      'active'
                  )}
                  onClick={() => {
                    onMediaSequenceSelected(template);
                    setIsUsingTemplate(true);
                  }}
                >
                  {template.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      <AutocompleteAddress
        label={'Adresse'}
        registration={() => register('name', { required: true })}
        formWatch={watch('name') || ''}
        formSetValue={(value: string) => setValue('name', value)}
        inputClassName="input input-bordered"
        onSuggestionSelectedFn={setSelectedSuggestion}
      />

      <PermissionProtectedComponent
        permissions={[EPermission.UserSimpleSearch]}
      >
        <div className="form-control">
          <LabelWithHelperText
            label="Tilknyt mægler"
            helperText="Det er valgfrit, om du ønsker at tilknytte en mægler til videoen. Hvis du vælger at tilknytte en mægler, kan vi automatisk indhente stamdata for den."
            inDialog={inDialog}
          />
          <EntitySelectSingleAsync<UserSimpleResponse, { searchTerm?: string }>
            useSearchFunction={useSearchUsers}
            searchParams={{}}
            renderSuggestion={(userSuggestion) => (
              <>
                {userSuggestion.name}{' '}
                {userSuggestion.title ? `(${userSuggestion.title})` : undefined}
              </>
            )}
            onSuggestionSelected={onUserSelected}
            inDialog={inDialog}
          />

          <input
            {...register('metaMappingUserId', { required: false })}
            type="text"
            className="input input-bordered hidden"
          />
        </div>
      </PermissionProtectedComponent>

      <PermissionProtectedComponent permissions={[EPermission.ModuleProject]}>
        <div className="form-control">
          <LabelWithHelperText
            label="Tilknyt sag"
            helperText="Det er valgfrit, om du ønsker at tilknytte en sag til videoen. Hvis du vælger at tilknytte en sag, kan vi automatisk indhente stamdata for den."
            inDialog={inDialog}
          />
          <EntitySelectSingleAsync<CaseResponse, { searchTerm?: string }>
            useSearchFunction={useSearchCases}
            searchParams={{}}
            renderSuggestion={(caseSuggestion) =>
              caseSuggestion.project.singleCase ? (
                <>{caseSuggestion.address}</>
              ) : (
                <>
                  {caseSuggestion.address} - {caseSuggestion.project.name}
                </>
              )
            }
            onSuggestionSelected={onCaseSelected}
            initialValue={existingCase}
            inDialog={inDialog}
          />

          <input
            {...register('caseId', { required: false })}
            type="text"
            className="input input-bordered hidden"
          />
        </div>
      </PermissionProtectedComponent>

      {!isUsingTemplate ? (
        <div className="form-control">
          <LabelWithHelperText
            label="Brug eksisterende video som skabelon"
            helperText="Du har mulighed for at vælge en eksisterende video som udgangspunkt for din nye video. Dette vil kopiere alle indstillinger fra den valgte video."
            inDialog={inDialog}
          />
          <EntitySelectSingleAsync<
            MediaSequenceResponse,
            { searchTerm?: string }
          >
            useSearchFunction={useSearchMediaSequences}
            searchParams={{}}
            renderSuggestion={(mediaSequenceSuggestion) => (
              <>
                {mediaSequenceSuggestion.isTemplate ? (
                  <span className="font-bold mr-1">[Skabelon]</span>
                ) : null}
                {mediaSequenceSuggestion.name}
              </>
            )}
            onSuggestionSelected={onMediaSequenceSelected}
            initialValue={existingMediaSequence}
            inDialog={inDialog}
          />
        </div>
      ) : null}

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPending || IsPendingClone}
        >
          Opret
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
