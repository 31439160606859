import { useMutation, useQuery } from '@tanstack/react-query';

import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  DynamicTemplateCreateRequest,
  DynamicTemplatesApi,
  DynamicTemplateUpdateRequest,
} from './core';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'App';
import { toast } from 'react-toastify';

export const DynamicTemplatesApiKeys = {
  GET_DYNAMIC_TEMPLATES_SEARCH: 'dynamic-templates-search',
  GET_DYNAMIC_TEMPLATES: 'dynamic-templates',
  GET_DYNAMIC_TEMPLATE: 'dynamic-template',
};

const client = new DynamicTemplatesApi(new CoreBaseConfiguration());

// TODO: Improve this with pagination or something?
export const useSearchDynamicTemplates = ({
  searchTerm,
}: {
  searchTerm?: string;
}) => {
  return useQuery({
    queryKey: [
      DynamicTemplatesApiKeys.GET_DYNAMIC_TEMPLATES_SEARCH,
      searchTerm,
    ],
    queryFn: () =>
      client.mediaOrbitDynamicTemplatesSearchGet({
        size: 50,
        searchTerm,
      }),
    enabled: searchTerm !== undefined || searchTerm != '',
  });
};

export const useGetDynamicTemplates = (state?: TableQueryState) => {
  return useQuery({
    queryKey: [DynamicTemplatesApiKeys.GET_DYNAMIC_TEMPLATES, state],
    queryFn: () =>
      client.mediaOrbitDynamicTemplatesGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
  });
};

export const useGetDynamicTemplate = (id?: string) => {
  return useQuery({
    queryKey: [DynamicTemplatesApiKeys.GET_DYNAMIC_TEMPLATE, id],
    queryFn: () => client.mediaOrbitDynamicTemplatesIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateDynamicTemplate = () => {
  return useMutation({
    mutationFn: ({
      dynamicTemplateCreateRequest,
    }: {
      dynamicTemplateCreateRequest: DynamicTemplateCreateRequest;
    }) =>
      client.mediaOrbitDynamicTemplatesPost({ dynamicTemplateCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [DynamicTemplatesApiKeys.GET_DYNAMIC_TEMPLATES],
      });
      toast.success(`Dynammisk overlejring '${e.name}' er blevet oprettet`, {
        toastId: 'createDynamicTemplate',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke oprette dynamisk overlejring');
    },
  });
};

export const useUpdateDynamicTemplate = () => {
  return useMutation({
    mutationFn: ({
      id,
      dynamicTemplateUpdateRequest,
    }: {
      id: string;
      dynamicTemplateUpdateRequest: DynamicTemplateUpdateRequest;
    }) =>
      client.mediaOrbitDynamicTemplatesIdPut({
        id,
        dynamicTemplateUpdateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [DynamicTemplatesApiKeys.GET_DYNAMIC_TEMPLATES],
      });
      queryClient.invalidateQueries({
        queryKey: [DynamicTemplatesApiKeys.GET_DYNAMIC_TEMPLATE, e.id],
      });
      toast.success(`Dynammisk overlejring '${e.name}' er blevet opdateret`, {
        toastId: 'updateDynamicTemplate',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke opdatere dynamisk overlejring');
    },
  });
};

export const useDeleteDynamicTemplate = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.mediaOrbitDynamicTemplatesIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [DynamicTemplatesApiKeys.GET_DYNAMIC_TEMPLATES],
      });
      queryClient.invalidateQueries({
        queryKey: [DynamicTemplatesApiKeys.GET_DYNAMIC_TEMPLATE, e.id],
      });
      toast.success(`Dynammisk overlejring '${e.name}' er blevet slettet`, {
        toastId: 'deleteDynamicTemplate',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke slette dynamisk overlejring');
    },
  });
};
