import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { Tooltip } from 'components/Tooltip';

interface LabelWithHelperTextProps {
  label: string;
  helperText: string;
  inDialog?: boolean;
}

export const LabelWithHelperText = ({
  label,
  helperText,
  inDialog = false,
}: LabelWithHelperTextProps) => {
  // TODO: Maybe we can find a better info icon to use here
  return (
    <label className="label">
      <span className="label-text">{label}</span>
      {helperText ? (
        <Tooltip position={inDialog ? 'left' : undefined} tooltip={helperText}>
          <AnimatedIcon icon="help-icon" className="h-8 md:h-5 w-8 md:w-5" />
        </Tooltip>
      ) : null}
    </label>
  );
};
