import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetDynamicTemplates } from 'api/useDynamicTemplatesApi';
import { DynamicTemplatesTable } from './components/DynamicTemplate/DynamicTemplatesTable';
import { DynamicTemplateCreate } from './components/DynamicTemplate/DynamicTemplateCreate';
import { EPermission } from 'api/core';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';

export const DynamicTemplatesView = () => {
  const { state } = useTableQueryState();
  const { data, isFetching } = useGetDynamicTemplates(state);

  return (
    <Page
      title={'Dynamiske overlejringer'}
      breadcrumbs={[{ name: 'Dynamiske overlejringer' }]}
    >
      <PermissionProtectedComponent
        permissions={[EPermission.DynamicTemplateWrite]}
      >
        <DynamicTemplateCreate
          trigger={
            <div className="flex justify-start py-4 w-full">
              <button className="btn btn-primary mr-2">
                Opret dynamisk overlejring
              </button>
            </div>
          }
        />
      </PermissionProtectedComponent>
      <DynamicTemplatesTable
        data={data}
        isFetching={isFetching}
        queryState={state}
      />
    </Page>
  );
};
