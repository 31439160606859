import {
  VisualIdentityCreateRequest,
  VisualIdentityResponse,
  VisualIdentityUpdateRequest,
} from 'api/core';
import {
  useCreateVisualIdentity,
  useUpdateVisualIdentity,
} from 'api/useVisualIdentitiesApi';
import { useForm } from 'react-hook-form';

interface VisualIdentityFormProps {
  targetVisualIdentity?: VisualIdentityResponse;
  onSuccess: () => void;
  onCancel: () => void;
}

export const VisualIdentityForm = ({
  targetVisualIdentity,
  onSuccess,
  onCancel,
}: VisualIdentityFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateVisualIdentity();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateVisualIdentity();

  const { register, handleSubmit } = useForm<
    VisualIdentityCreateRequest | VisualIdentityUpdateRequest
  >({
    defaultValues: targetVisualIdentity,
  });

  const onSubmit = handleSubmit(async (result) => {
    if (targetVisualIdentity) {
      await updateAsync({
        id: targetVisualIdentity.id,
        visualIdentityUpdateRequest: result,
      });
    } else {
      await createAsync({
        visualIdentityCreateRequest: result,
      });
    }
    onSuccess();
  });

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Navn</span>
        </label>
        <input
          {...register('name', { required: true })}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Tema</span>
        </label>
        <select
          {...register('tailwindName', { required: true })}
          className="select select-bordered"
        >
          <option value="default">Standard</option>
          <option value="edc">EDC</option>
        </select>
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPendingCreate || isPendingUpdate}
        >
          {targetVisualIdentity ? 'Opdater' : 'Opret'}
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
