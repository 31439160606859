import { AssetResponse, AssetSimpleResponse, EAssetType } from 'api/core';
import { VideoPreviewWithTrim } from './VideoPreviewWithTrim';

interface VideoPreviewProps {
  asset: AssetResponse | AssetSimpleResponse;
  className?: string | undefined;
  trimStart?: number;
  trimEnd?: number;
}

export const VideoPreview = ({
  asset,
  className,
  trimStart,
  trimEnd,
}: VideoPreviewProps) => {
  if (asset.type !== EAssetType.Video) return null;

  if (trimStart && trimEnd) {
    return (
      <VideoPreviewWithTrim
        asset={asset}
        trimStart={trimStart}
        trimEnd={trimEnd}
        style={{ width: '100%' }}
      />
    );
  }

  return (
    <video className={className} controls>
      <source src={asset.url} type="video/mp4" /> Your browser does not support
      the video tag.
    </video>
  );
};
