import {
  MediaSequenceBranchCreateRequest,
  MediaSequenceBranchResponse,
  MediaSequenceBranchUpdateRequest,
} from 'api/core';
import { useForm } from 'react-hook-form';
import { BRANCH_TEMPLATE_FORMATS, TemplateFormat } from './BranchTemplates';
import { EntitySelectSingle } from 'components/Select/EntitySelectSingle';
import {
  useCreateMediaSequenceBranch,
  useUpdateMediaSequenceBranch,
} from 'api/useMediaSequenceBranchesApi';

interface MediaSequenceBranchFormProps {
  targetBranch?: MediaSequenceBranchResponse;
  mediaSequenceId?: string;
  onSuccess: () => void;
  onCancel: () => void;
  inDialog?: boolean;
}

export const MediaSequenceBranchForm = ({
  targetBranch,
  mediaSequenceId,
  onSuccess,
  onCancel,
  inDialog,
}: MediaSequenceBranchFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateMediaSequenceBranch();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateMediaSequenceBranch();

  const { register, handleSubmit, setValue } = useForm<
    MediaSequenceBranchCreateRequest | MediaSequenceBranchUpdateRequest
  >({
    defaultValues: targetBranch,
  });

  const onSubmit = handleSubmit(async (result) => {
    if (targetBranch) {
      await updateAsync({
        id: targetBranch.id,
        mediaSequenceBranchUpdateRequest: result,
      });
    } else {
      if (!mediaSequenceId) return;
      await createAsync({
        mediaSequenceId,
        mediaSequenceBranchCreateRequest: result,
      });
    }
    onSuccess();
  });

  const onTemplateFormatSelected = (templateFormat: TemplateFormat | null) => {
    if (!templateFormat) return;
    setValue('name', templateFormat.name);
    setValue('width', templateFormat.width);
    setValue('height', templateFormat.height);
  };

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Format skabelon</span>
        </label>
        <EntitySelectSingle<TemplateFormat>
          data={BRANCH_TEMPLATE_FORMATS}
          renderFormat={(format) => <>{format.name}</>}
          onSelect={onTemplateFormatSelected}
          searchPropertyKey="name"
          inDialog={inDialog}
        />
        <div className="label">
          <span className="label-text-alt">
            Du kan vælge et kendt format fra listen som vil udfylde navn, bredde
            og højde. Hvis det er et nyt format, så udfyld felterne manuelt.
          </span>
        </div>
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Navn</span>
        </label>
        <input
          {...register('name', { required: true })}
          type="text"
          className="input input-bordered"
        />
      </div>
      <div className="form-control">
        <label className="label">
          <span className="label-text">Bredde</span>
        </label>
        <input
          {...register('width', { required: true })}
          type="number"
          min={400}
          max={1920}
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Højde</span>
        </label>
        <input
          {...register('height', { required: true })}
          type="number"
          min={400}
          max={1920}
          className="input input-bordered"
        />
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPendingCreate || isPendingUpdate}
        >
          {targetBranch ? 'Opdater' : 'Opret'}
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
