import { PaginationState, SortingState } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface TableQueryState {
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

interface TableQueryStateResult {
  state: TableQueryState;
}

export const useTableQueryState = (): TableQueryStateResult => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialStates = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return {
      globalFilter: query.get('filter') ?? '',
      sorting: query.get('sort') ? JSON.parse(query.get('sort') || '[]') : [],
      pageIndex: parseInt(query.get('page') ?? '0'),
      pageSize: parseInt(query.get('size') ?? '10'),
    };
  }, [location.search]);

  const [globalFilter, setGlobalFilter] = useState(initialStates.globalFilter);
  const [sorting, setSorting] = useState<SortingState>(initialStates.sorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: initialStates.pageIndex,
    pageSize: initialStates.pageSize,
  });

  const setQueryParams = useCallback(
    (params: Record<string, string | number | undefined>) => {
      const searchParams = new URLSearchParams(location.search);
      let hasChanged = false;
      Object.entries(params).forEach(([key, value]) => {
        if (key === 'sort' && value === '[]') {
          searchParams.delete(key);
        } else if (key === 'filter' && value === '') {
          searchParams.delete(key);
        } else if (
          value !== undefined &&
          value.toString() !== searchParams.get(key)
        ) {
          searchParams.set(key, value.toString());
          hasChanged = true;
        }
      });
      if (hasChanged) {
        navigate(
          { ...location, search: searchParams.toString() },
          { replace: true }
        );
      }
    },
    [navigate, location]
  );

  useEffect(() => {
    setQueryParams({
      page: pagination.pageIndex,
      size: pagination.pageSize,
      sort: JSON.stringify(sorting),
      filter: globalFilter,
    });
  }, [pagination, sorting, globalFilter, setQueryParams]);

  return {
    state: {
      pagination,
      setPagination,
      globalFilter,
      setGlobalFilter,
      sorting,
      setSorting,
    },
  };
};

export const buildQueryState = <T extends object>(params: {
  pagination?: { pageIndex: number; pageSize: number };
  sorting?: {
    desc: boolean;
    id: string & keyof T;
  }[];
}): TableQueryState => {
  return {
    pagination: {
      pageIndex: params.pagination?.pageIndex ?? 0,
      pageSize: params.pagination?.pageSize ?? 10,
    },
    sorting: params.sorting ?? [],
    setGlobalFilter: () => null,
    setPagination: () => null,
    setSorting: () => null,
    globalFilter: '',
  };
};
