import {
  ETranscriptionState,
  MediaSequenceResponse,
  MediaSequencesIdTranscriptionPutRequest,
} from 'api/core';
import {
  useApproveMediaSequenceTranscription,
  useGetMediaSequencePreviewVideo,
  useUpdateMediaSequenceTranscription,
} from 'api/useMediaSequencesApi';
import { MutateEntity } from 'components/Form/MutateEntity';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMediaSequenceSetup } from 'pages/MediaOrbit/components/Setup/MediaSequenceSetupProvider';
import { twMerge } from 'tailwind-merge';
import './MediaSequenceTranscriptionForm.css';
import { SubtitleForm } from 'components/Subtitle/SubtitleForm';

interface MediaSequenceUpdateFormProps {
  mediaSequence: MediaSequenceResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
}

export const MediaSequenceTranscriptionForm = ({
  mediaSequence,
  onSuccess,
  onCancel,
  disabled,
}: MediaSequenceUpdateFormProps) => {
  const { mutateAsync, isPending } = useUpdateMediaSequenceTranscription();
  const { handleNext } = useMediaSequenceSetup();
  const videoRef = useRef<HTMLVideoElement>(null);

  const [subtitleUrl, setSubtitleUrl] = useState('');
  const [isBodyChanged, setIsBodyChanged] = useState(false);
  const [subtitleFormHasErrors, setSubtitleFormHasErrors] = useState(false);
  const [videnLengthInSeconds, setVideoLengthInSeconds] = useState<number>();

  const { data: previewUrl } = useGetMediaSequencePreviewVideo(
    disabled ||
      mediaSequence.transcriptionState !== ETranscriptionState.Generated
      ? undefined
      : mediaSequence.id
  );

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--branch-width',
      `${mediaSequence.branches?.[0]?.width ?? 1080}px`
    );
    document.documentElement.style.setProperty(
      '--branch-height',
      `${mediaSequence.branches?.[0]?.height ?? 1080}px`
    );
  }, [mediaSequence]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
    setValue,
  } = useForm<MediaSequencesIdTranscriptionPutRequest>({
    mode: 'onChange',
    defaultValues: {
      id: mediaSequence.id,
      body: mediaSequence.transcription ?? '',
    },
    disabled,
  });

  const currentBody = watch('body');
  const [curentWebVtt, setCurentWebVtt] = useState<string>();

  useEffect(() => {
    if (!currentBody) return;
    const generatedWebVttData =
      'WEBVTT\n\n' +
      currentBody.replace(/(\d{2}):(\d{2}):(\d{2}),(\d{3})/g, '$1:$2:$3.$4');

    if (generatedWebVttData.split('\n').length < 4) return;

    if (curentWebVtt === generatedWebVttData) return;
    setCurentWebVtt(generatedWebVttData);

    const blob = new Blob([generatedWebVttData], { type: 'text/vtt' });
    const url = URL.createObjectURL(blob);
    setSubtitleUrl(url);
  }, [currentBody, curentWebVtt]);

  useEffect(() => {
    return () => URL.revokeObjectURL(subtitleUrl);
  }, [subtitleUrl]);

  useEffect(() => {
    setIsBodyChanged(currentBody !== (mediaSequence.transcription ?? ''));
  }, [currentBody, mediaSequence.transcription]);

  useEffect(() => {
    if (videoRef.current && subtitleUrl) {
      const trackElement = videoRef.current.querySelector('track');
      if (trackElement) {
        trackElement.default = true;
        videoRef.current.textTracks[0].mode = 'showing';
      }
    }
  }, [subtitleUrl]);

  const onSubmit = handleSubmit(async (result) => {
    await mutateAsync({ request: result });
    onSuccess?.();
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col md:flex-row gap-4">
        <div className={twMerge('w-full', previewUrl && 'md:w-1/2')}>
          <SubtitleForm
            subtitleString={currentBody}
            videoLengthInSeconds={videnLengthInSeconds}
            onChanged={(e) => setValue('body', e)}
            onErrorsChanged={setSubtitleFormHasErrors}
            disabled={disabled}
          />
          <textarea
            {...register('body', { required: true })}
            className="hidden"
          ></textarea>
        </div>
        {previewUrl ? (
          <div className="flex flex-col md:w-1/3 gap-4">
            <video
              id="subtitles-preview"
              style={{ maxHeight: '450px' }}
              controls
              ref={videoRef}
              onLoadedMetadata={(e) => {
                const duration = Math.ceil(e.currentTarget.duration * 10) / 10;
                setVideoLengthInSeconds(duration);
              }}
              onEmptied={() => setVideoLengthInSeconds(undefined)}
            >
              <source src={previewUrl} type="video/mp4" />
              {subtitleUrl ? (
                <track
                  key={subtitleUrl}
                  src={subtitleUrl}
                  kind="subtitles"
                  srcLang="da"
                  label="Dansk"
                  default
                />
              ) : null}
            </video>
          </div>
        ) : null}
      </div>

      {disabled ? null : (
        <div className="flex justify-center space-x-4 pt-4 w-full">
          <button
            id="subtitles-edit-subtitles"
            className="btn btn-primary"
            type="submit"
            disabled={
              isPending || !isBodyChanged || !isValid || subtitleFormHasErrors
            }
          >
            Gem ændringer
          </button>
          {onCancel ? (
            <button type="button" className="btn" onClick={onCancel}>
              Annuller
            </button>
          ) : null}
          <MutateEntity
            trigger={
              <button
                id="subtitles-approve-subtitles"
                type="button"
                className="btn btn-success mr-2"
                disabled={isBodyChanged || subtitleFormHasErrors}
              >
                Godkend undertekster
              </button>
            }
            title={`Godkend underteksterne til videoen ${mediaSequence.name}?`}
            description={`Er du sikker på du vil godkende underteksterne til videoen '${mediaSequence.name}'? De vil blive låst efterfølgende.`}
            setter={useApproveMediaSequenceTranscription}
            mutationVariables={{ id: mediaSequence.id }}
            onMutate={handleNext}
            disabled={isBodyChanged}
          />
        </div>
      )}
    </form>
  );
};
