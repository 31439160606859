import { ColumnDef } from '@tanstack/react-table';
import { MediaSequenceBranchResponse, MediaSequenceResponse } from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { useMemo, useState } from 'react';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { MediaSequenceBranchUpdate } from './MediaSequenceBranchUpdate';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { LinkCell } from 'components/Table/Cell/LinkCell';
import {
  useDeleteMediaSequenceBranch,
  useGetMediaSequenceBranch,
} from 'api/useMediaSequenceBranchesApi';
import { useIsMobile } from 'utils/useIsMobile';
import { AssetPreviewModal } from 'components/Asset/AssetPreviewModal';

interface TableProps {
  data?: MediaSequenceResponse;
  isFetching: boolean;
  disabled?: boolean;
  showResult?: boolean;
}

export const MediaSequenceBranchesTable = ({
  data,
  isFetching,
  disabled,
  showResult,
}: TableProps) => {
  const isMobile = useIsMobile();
  const [editTarget, setEditTarget] =
    useState<MediaSequenceBranchResponse | null>(null);
  const [deleteTarget, setDeleteTarget] =
    useState<MediaSequenceBranchResponse | null>(null);

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<MediaSequenceBranchResponse>[] = [
      {
        accessorKey: 'name',
        header: 'Format',
        enableSorting: false,
      },
      {
        accessorKey: 'width',
        header: 'Bredde',
        enableSorting: false,
      },
      {
        accessorKey: 'height',
        header: 'Højde',
        enableSorting: false,
      },
      {
        accessorKey: '_result',
        header: 'Resultat',
        enableSorting: false,
        cell: (e) =>
          e.row.original.asset != null ? (
            <div id="results-result" className="max-w-60">
              <AssetPreviewModal asset={e.row.original.asset} />
            </div>
          ) : (
            <span className="loading loading-spinner text-primary loading-md"></span>
          ),
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-3">
            {showResult && e.row.original.asset ? (
              <LinkCell
                icon={
                  <AnimatedIcon
                    icon="download-icon"
                    className="h-8 md:h-6 w-8 md:w-6"
                  />
                }
                path={e.row.original.asset.url}
                tooltip="Download resultat"
                isExternal
              />
            ) : null}
            {!disabled ? (
              <div className="flex space-x-5 md:space-x-3">
                <ActionCell
                  icon={
                    <div id="branches-edit-branch">
                      <AnimatedIcon
                        icon="pencil-icon"
                        className="h-8 md:h-6 w-8 md:w-6"
                      />
                    </div>
                  }
                  onClick={() => setEditTarget(e.row.original)}
                />
                <ActionCell
                  icon={
                    <div id="branches-delete-branch">
                      <AnimatedIcon
                        icon="trash-icon"
                        className="h-8 md:h-6 w-8 md:w-6"
                      />
                    </div>
                  }
                  onClick={() => setDeleteTarget(e.row.original)}
                />
              </div>
            ) : null}
          </div>
        ),
      },
    ];
    return baseColumns;
  }, [setEditTarget, setDeleteTarget, showResult, disabled]);

  const table = useGetCustomReactTable(
    data?.branches ?? [],
    -1,
    columns,
    undefined,
    {
      columnVisibility: {
        _result: showResult ?? false,
        width: !isMobile,
        height: !isMobile,
      },
    }
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} hidePagination />
      {editTarget ? (
        <MediaSequenceBranchUpdate
          id={editTarget?.id}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetMediaSequenceBranch}
          deleter={useDeleteMediaSequenceBranch}
          disabled={disabled}
        />
      ) : null}
    </>
  );
};
