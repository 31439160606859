import { EAssetType, MediaSequenceAssetResponse } from 'api/core';
import { VideoPreviewWithTrim } from 'components/Video/VideoPreviewWithTrim';
import { twMerge } from 'tailwind-merge';

interface ColorAdjustmentsPreviewProps {
  asset: MediaSequenceAssetResponse;
  contrast?: number;
  brightness?: number;
  saturation?: number;
  className?: string;
}

export const ColorAdjustmentsPreview = ({
  asset,
  brightness = 0,
  contrast = 1,
  saturation = 1,
  className,
}: ColorAdjustmentsPreviewProps) => {
  const brightnessValue = +brightness;
  const contrastValue = +contrast;
  const saturationValue = +saturation;

  const cssBrightness = (1 + brightnessValue * 5).toFixed(2);

  const filterStyles = {
    filter: `brightness(${cssBrightness}) contrast(${contrastValue}) saturate(${saturationValue})`,
  };

  const overlayStyles = {
    backgroundColor:
      brightnessValue > 0 ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
    opacity: Math.abs(brightnessValue),
  };

  const assetTrimMutation = asset.mutations.find(
    (e) => e.trimVideo !== undefined
  )?.trimVideo;

  return (
    <div
      className={twMerge('overflow-hidden relative w-full h-full', className)}
    >
      {asset.asset.type === EAssetType.Video ? (
        <>
          <VideoPreviewWithTrim
            asset={asset.asset}
            className="w-full h-full"
            style={filterStyles}
            loop
            muted
            trimStart={assetTrimMutation?.trimStartSeconds}
            trimEnd={assetTrimMutation?.trimEndSeconds}
          />
        </>
      ) : null}
      {asset.asset.type === EAssetType.Image ? (
        <img
          src={asset.asset.url}
          alt={asset.asset.originalFileName}
          className="w-full h-full"
          style={filterStyles}
        />
      ) : null}
      <div
        className="absolute top-0 left-0 w-full h-full pointer-events-none mix-blend-overlay opacity-0"
        style={overlayStyles}
      ></div>
    </div>
  );
};
