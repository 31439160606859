/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EFieldType = {
    Text: 'TEXT',
    Number: 'NUMBER',
    Area: 'AREA',
    SubsidyArea: 'SUBSIDY_AREA',
    Currency: 'CURRENCY',
    Rent: 'RENT',
    Boolean: 'BOOLEAN',
    Date: 'DATE',
    LongtextAdWindow: 'LONGTEXT_AD_WINDOW',
    LongtextAdWebsite: 'LONGTEXT_AD_WEBSITE',
    LongtextAdSocialMedia: 'LONGTEXT_AD_SOCIAL_MEDIA',
    Longtext: 'LONGTEXT',
    HousingType: 'HOUSING_TYPE'
} as const;
export type EFieldType = typeof EFieldType[keyof typeof EFieldType];


export function EFieldTypeFromJSON(json: any): EFieldType {
    return EFieldTypeFromJSONTyped(json, false);
}

export function EFieldTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EFieldType {
    return json as EFieldType;
}

export function EFieldTypeToJSON(value?: EFieldType | null): any {
    return value as any;
}

