import { ControlledModal } from 'components/ControlledModal';
import { ServiceTicketForm } from './ServiceTicketForm';
import { useState } from 'react';

interface ServiceTicketCreateProps {
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const ServiceTicketCreate = ({
  trigger,
  isInitialOpen,
  onClosed,
}: ServiceTicketCreateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opret service ticket"
        closeOnOutsideClick={false}
        hideActionBar
      >
        <ServiceTicketForm
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
        />
      </ControlledModal>
    </>
  );
};
