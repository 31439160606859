import { useMutation, useQuery } from '@tanstack/react-query';

import { ExternalIntegrationsApi, FacebookCreateRequest } from './core';
import { queryClient } from 'App';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import { TableQueryState } from 'components/Table/useTableQueryState';

export const ExternalIntegrationApiKeys = {
  GET_EXTERNAL_INTEGRATIONS: 'externalIntegrations',
  GET_EXTERNAL_INTEGRATION: 'externalIntegration',
};

const client = new ExternalIntegrationsApi(new CoreBaseConfiguration());

export const useGetExternalIntegrations = (state?: TableQueryState) => {
  return useQuery({
    queryKey: [ExternalIntegrationApiKeys.GET_EXTERNAL_INTEGRATIONS, state],
    queryFn: () =>
      client.externalIntegrationsGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
  });
};

export const useGetFacebookPageName = () => {
  return useQuery({
    queryKey: [ExternalIntegrationApiKeys.GET_EXTERNAL_INTEGRATIONS, 'FB'],
    queryFn: async () => {
      const data = await client.externalIntegrationsFacebookPageNameGet();
      return data;
    },
  });
};

export const useGetExternalIntegration = (id?: string) => {
  return useQuery({
    queryKey: [ExternalIntegrationApiKeys.GET_EXTERNAL_INTEGRATION, id],
    queryFn: async () => {
      const data = await client.externalIntegrationsIdGet({ id: id ?? '' });
      return data;
    },
    enabled: id !== undefined,
  });
};
export const useCreateFacebookIntegration = () => {
  return useMutation({
    mutationFn: ({
      facebookCreateRequest,
    }: {
      facebookCreateRequest: FacebookCreateRequest;
    }) => client.externalIntegrationsFacebookPost({ facebookCreateRequest }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ExternalIntegrationApiKeys.GET_EXTERNAL_INTEGRATIONS],
      });
      toast.success(`Integrationen til Facebook er blevet oprettet`, {
        toastId: 'facebookCreateRequest',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke oprette integrationen til Facebook');
    },
  });
};

export const useDeleteExternalIntegration = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.externalIntegrationsIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [ExternalIntegrationApiKeys.GET_EXTERNAL_INTEGRATIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [ExternalIntegrationApiKeys.GET_EXTERNAL_INTEGRATION, e.id],
      });
      toast.success(`Integrationen er blevet slettet`, {
        toastId: 'deleteExternalIntegration',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke slette integrationen');
    },
  });
};
