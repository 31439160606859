/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPermission } from './EPermission';
import {
    EPermissionFromJSON,
    EPermissionFromJSONTyped,
    EPermissionToJSON,
} from './EPermission';

/**
 * 
 * @export
 * @interface OrganizationCreateRequest
 */
export interface OrganizationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    cvr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    visualIdentityId: string;
    /**
     * 
     * @type {Array<EPermission>}
     * @memberof OrganizationCreateRequest
     */
    modules: Array<EPermission>;
}

/**
 * Check if a given object implements the OrganizationCreateRequest interface.
 */
export function instanceOfOrganizationCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "visualIdentityId" in value;
    isInstance = isInstance && "modules" in value;

    return isInstance;
}

export function OrganizationCreateRequestFromJSON(json: any): OrganizationCreateRequest {
    return OrganizationCreateRequestFromJSONTyped(json, false);
}

export function OrganizationCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'cvr': !exists(json, 'cvr') ? undefined : json['cvr'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'visualIdentityId': json['visualIdentityId'],
        'modules': ((json['modules'] as Array<any>).map(EPermissionFromJSON)),
    };
}

export function OrganizationCreateRequestToJSON(value?: OrganizationCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': value.address,
        'phone': value.phone,
        'website': value.website,
        'city': value.city,
        'cvr': value.cvr,
        'zipCode': value.zipCode,
        'email': value.email,
        'visualIdentityId': value.visualIdentityId,
        'modules': ((value.modules as Array<any>).map(EPermissionToJSON)),
    };
}

