import { CheckIcon, XIcon } from 'lucide-react';

interface BooleanCellProps {
  value: boolean;
}

export const BooleanCell = ({ value }: BooleanCellProps) => {
  return value ? (
    <CheckIcon className="h-6 w-6 text-success" />
  ) : (
    <XIcon className="h-6 w-6 text-error" />
  );
};
