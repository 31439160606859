import { EventCalendar } from 'components/Calendar/EventCalendar';
import { useGetPublicationsByDateRange } from 'api/usePublicationsApi';
import { Event } from 'react-big-calendar';
import { twMerge } from 'tailwind-merge';
import { PublicationResponse } from 'api/core';
import { PublicationUpdate } from './PublicationUpdate';
import { useState } from 'react';
import { LockIcon } from 'lucide-react';

export const PublicationsCalendar = () => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const { data: publications, isFetching: isFetchingPublications } =
    useGetPublicationsByDateRange(startDate, endDate);

  const [editTarget, setEditTarget] = useState<PublicationResponse | null>(
    null
  );

  const events =
    publications?.map((publication) => {
      let title = <>{publication.name}</>;
      if (publication.state === 'Scheduled')
        title = (
          <>
            <LockIcon className="inline-block w-5 h-5 me-1" />
            {title}
          </>
        );
      return {
        allDay: false,
        start: new Date(publication.scheduledTimeUtc),
        end: new Date(publication.scheduledTimeUtc),
        title: title,
        resource: publication,
      } as Event;
    }) ?? [];

  const onDateRangeChange = (newStartDate: Date, newEndDate: Date) => {
    // Check current start and end date to avoid unnecessary refetching
    if (
      startDate?.getTime() === newStartDate.getTime() &&
      endDate?.getTime() === newEndDate.getTime()
    )
      return;

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  return (
    <>
      <div
        className={twMerge(
          isFetchingPublications && 'opacity-40 pointer-events-none select-none'
        )}
      >
        <EventCalendar
          calendarStyle={{ height: '70vh' }}
          events={events}
          onEventDoubleClick={setEditTarget}
          onDateRangeChange={onDateRangeChange}
        />
        {editTarget ? (
          <PublicationUpdate
            id={editTarget?.id}
            isInitialOpen={true}
            onClosed={() => setEditTarget(null)}
          />
        ) : null}
      </div>
    </>
  );
};
