import { useGetCases } from 'api/useCasesApi';
import { useGetProject } from 'api/useProjectsApi';
import { Page } from 'layouts/Admin/Page';
import { useParams } from 'react-router-dom';
import { CasesTable } from './components/Case/CasesTable';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { useGetFields } from 'api/useFieldsApi';
import { CaseCreate } from './components/Case/CaseCreate';
import { EPermission } from 'api/core';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';

export const ProjectCases = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useTableQueryState();
  const { data: project } = useGetProject(id, true);
  const { data: cases, isFetching: isFetchingCases } = useGetCases(id, state);
  const { data: fields } = useGetFields(id);

  if (project?.singleCase) return null;

  return (
    <Page
      title={project?.name}
      breadcrumbs={[
        { name: 'Projekter' },
        { name: project?.name ?? '' },
        { name: 'Sager' },
      ]}
    >
      <PermissionProtectedComponent permissions={[EPermission.CaseCreate]}>
        <CaseCreate
          projectId={id as string}
          trigger={
            <div className="flex justify-start py-4 w-full">
              <button className="btn btn-primary mr-2">Opret sag</button>
            </div>
          }
        />
      </PermissionProtectedComponent>

      <CasesTable
        projectId={id as string}
        data={cases}
        fields={fields?.data ?? []}
        isFetching={isFetchingCases}
        queryState={state}
      />
    </Page>
  );
};
