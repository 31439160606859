import { ColumnDef } from '@tanstack/react-table';
import { CustomTable } from 'components/Table/CustomTable';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useMemo, useState } from 'react';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import {
  EPermission,
  PublicationResponse,
  PublicationResponsePagedData,
} from 'api/core';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import {
  useDeletePublication,
  useGetPublication,
} from 'api/usePublicationsApi';
import { BadgeCell } from 'components/Table/Cell/BadgeCell';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { publicationStateTranslate } from 'utils/enum-translate';
import { PublicationUpdate } from './PublicationUpdate';

interface TableProps {
  data?: PublicationResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const PublicationsTable = ({
  data,
  isFetching,
  queryState,
}: TableProps) => {
  const [editTarget, setEditTarget] = useState<PublicationResponse | null>(
    null
  );
  const [deleteTarget, setDeleteTarget] = useState<PublicationResponse | null>(
    null
  );

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<PublicationResponse>[] = [
      {
        accessorKey: 'name',
        header: 'Navn',
      },
      {
        accessorKey: 'state',
        header: 'Status',
        cell: (e) => (
          <BadgeCell text={publicationStateTranslate(e.row.original.state)} />
        ),
      },
      {
        accessorKey: 'scheduledTimeUtc',
        header: 'Publiceringsdato',
        cell: (e) => <DateTimeCell value={e.row.original.scheduledTimeUtc} />,
      },
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              permissions={[EPermission.PublicationWrite]}
              icon={
                <AnimatedIcon
                  icon="pencil-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            <ActionCell
              permissions={[EPermission.PublicationDelete]}
              icon={
                <AnimatedIcon
                  icon="trash-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setDeleteTarget(e.row.original)}
            />
          </div>
        ),
      },
    ];
    return baseColumns;
  }, [setDeleteTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {editTarget ? (
        <PublicationUpdate
          id={editTarget?.id}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetPublication}
          deleter={useDeletePublication}
        />
      ) : null}
    </>
  );
};
