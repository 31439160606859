import { EPermission } from 'api/core';
import { useAuth } from 'auth/AuthProvider';
import { Tooltip, TooltipProps } from 'components/Tooltip';
import { ReactNode } from 'react';

interface ActionCellProps {
  icon: ReactNode;
  onClick: () => void;
  tooltip?: string;
  position?: TooltipProps['position'];
  disabled?: boolean;
  permissions?: EPermission[];
  permissionScope?: 'any' | 'all';
}

export const ActionCell = ({
  icon,
  onClick,
  tooltip,
  position,
  disabled,
  permissions,
  permissionScope = 'all',
}: ActionCellProps) => {
  const { hasPermissions, hasAnyPermissions } = useAuth();
  if (disabled) return null;

  if (permissions) {
    if (permissionScope === 'all' && !hasPermissions(permissions)) return null;
    if (permissionScope === 'any' && !hasAnyPermissions(permissions))
      return null;
  }

  return (
    <Tooltip tooltip={tooltip} position={position}>
      <button
        type="button"
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
      >
        {icon}
      </button>
    </Tooltip>
  );
};
