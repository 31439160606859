import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface TooltipProps {
  tooltip?: string;
  children: ReactNode;
  className?: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
}

export const Tooltip = ({
  tooltip,
  children,
  className,
  position,
}: TooltipProps) => {
  return (
    <span
      className={twMerge(
        className,
        'tooltip',
        position === 'top' && 'tooltip-top',
        position === 'right' && 'tooltip-right',
        position === 'bottom' && 'tooltip-bottom',
        position === 'left' && 'tooltip-left'
      )}
      data-tip={tooltip}
    >
      {children}
    </span>
  );
};
