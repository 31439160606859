import { ReactNode } from 'react';

import { useAuth } from './AuthProvider';
import { EPermission } from 'api/core';

interface PermissionProtectedComponentProps {
  permissions?: EPermission[];
  children: ReactNode;
}

export const PermissionProtectedComponent = ({
  permissions,
  children,
}: PermissionProtectedComponentProps) => {
  const { hasPermissions } = useAuth();

  if (!hasPermissions(permissions)) {
    return null;
  }

  return <>{children}</>;
};
