import { useGetFields } from 'api/useFieldsApi';
import { useGetProject } from 'api/useProjectsApi';
import { Page } from 'layouts/Admin/Page';
import { useParams } from 'react-router-dom';
import { FieldsTable } from './components/Field/FieldsTable';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { FieldCreate } from './components/Field/FieldCreate';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';

export const ProjectFields = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useTableQueryState();

  const { data: project } = useGetProject(id, true);
  const { data: fields, isFetching: isFetchingFields } = useGetFields(
    id,
    state
  );

  return (
    <Page
      title={project?.name}
      breadcrumbs={[
        { name: 'Projekter' },
        { name: project?.name ?? '' },
        { name: 'Felter' },
      ]}
    >
      <PermissionProtectedComponent permissions={[EPermission.FieldWrite]}>
        <FieldCreate
          projectId={id as string}
          trigger={
            <div className="flex justify-start py-4 w-full">
              <button className="btn btn-primary mr-2">Opret felt</button>
            </div>
          }
        />
      </PermissionProtectedComponent>

      <FieldsTable
        data={fields}
        isFetching={isFetchingFields}
        queryState={state}
        project={project}
      />
    </Page>
  );
};
