import { useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';

interface OptionType<T> {
  value: T;
  label: string;
  searchValue: string;
}

interface EntitySelectMultipleProps<T extends { id: string | number }> {
  data: T[];
  renderFormat: (input: T) => React.ReactNode;
  onSelect: (output: T[] | null) => void;
  initialValue?: T[];
  menuPlacement?: 'auto' | 'bottom' | 'top';
  searchPropertyKey: keyof T;
  inDialog?: boolean;
  disabled?: boolean;
}

export function EntitySelectMultiple<T extends { id: string | number }>({
  data,
  renderFormat,
  onSelect,
  initialValue = [],
  menuPlacement = 'auto',
  searchPropertyKey,
  inDialog,
  disabled,
}: EntitySelectMultipleProps<T>) {
  const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(
    null
  );
  const [selectedOptions, setSelectedOptions] = useState<OptionType<T>[]>(
    initialValue.map((value) => ({
      value,
      label: '',
      searchValue: value[searchPropertyKey] as unknown as string,
    }))
  );

  useEffect(() => {
    if (inDialog) {
      setMenuPortalTarget(document.querySelector('dialog'));
    } else {
      setMenuPortalTarget(document.querySelector('body'));
    }
  }, [inDialog]);

  return (
    <div className="form-control w-full">
      <Select
        id="entity-select-multiple"
        inputId="entity-select-multiple-input"
        options={data.map((value) => ({
          value,
          label: '',
          searchValue: value[searchPropertyKey] as unknown as string,
        }))}
        value={selectedOptions}
        onChange={(selectedOption: MultiValue<OptionType<T>>) => {
          const mappedOptions = selectedOption
            ? selectedOption.map((option) => option)
            : [];
          setSelectedOptions(mappedOptions);
          onSelect(mappedOptions.map((option) => option.value) ?? null);
        }}
        formatOptionLabel={(option) => <>{renderFormat(option.value)}</>}
        placeholder="Skriv for at søge..."
        isClearable
        isMulti
        closeMenuOnSelect={false}
        noOptionsMessage={() => 'Ingen resultater fundet'}
        menuPlacement={menuPlacement}
        getOptionValue={(option) => option.value.id.toString()}
        filterOption={(option, rawInput) => {
          const input = rawInput.toLowerCase();
          return option.data.searchValue.toLowerCase().includes(input);
        }}
        menuPortalTarget={menuPortalTarget}
        isDisabled={disabled}
      />
    </div>
  );
}
