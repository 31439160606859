import { twMerge } from 'tailwind-merge';

interface ProgressCellProps {
  val: number;
  maxVal: number;
  paused?: boolean;
}

export const ProgressCell = ({ val, maxVal, paused }: ProgressCellProps) => {
  const percentage = val / maxVal;
  return (
    <progress
      className={twMerge(
        'progress',
        percentage > 0.9 && 'progress-success',
        percentage > 0.3 && 'progress-secondary',
        percentage <= 0.3 && 'progress-accent',
        paused && 'progress-error'
      )}
      value={val}
      max={maxVal}
    >
      {val} / {maxVal}
    </progress>
  );
};
