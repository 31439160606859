import { ColumnDef } from '@tanstack/react-table';
import {
  EBranchMutationType,
  MediaSequenceBranchMutationResponse,
  MediaSequenceResponse,
} from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';

import { useMemo, useState } from 'react';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { MediaSequenceBranchMutationUpdate } from './MediaSequenceBranchMutationUpdate';
import {
  branchMutationTranslate,
  positionTranslate,
  resizeTranslate,
} from 'utils/enum-translate';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import {
  useDeleteMediaSequenceBranchMutation,
  useGetMediaSequenceBranchMutation,
} from 'api/useMediaSequenceBranchesApi';

interface TableProps {
  mediaSequence: MediaSequenceResponse;
  disabled?: boolean;
}

export const MediaSequenceBranchMutationsTable = ({
  mediaSequence,
  disabled,
}: TableProps) => {
  const [editTarget, setEditTarget] =
    useState<MediaSequenceBranchMutationResponse | null>(null);
  const [deleteTarget, setDeleteTarget] =
    useState<MediaSequenceBranchMutationResponse | null>(null);

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<MediaSequenceBranchMutationResponse>[] = [
      {
        accessorKey: 'type',
        header: 'Type',
        enableSorting: false,
        cell: (e) => branchMutationTranslate(e.row.original.type),
      },
      {
        accessorKey: 'description',
        header: 'Beskrivelse',
        enableSorting: false,
        cell: (e) => describeMutation(e.row.original),
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              icon={
                <div id="branch-mutations-edit-mutation">
                  <AnimatedIcon
                    icon={disabled ? 'zoom-icon' : 'pencil-icon'}
                    className="h-8 md:h-6 w-8 md:w-6"
                  />
                </div>
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            {e.row.original.type !== EBranchMutationType.ResizeAndConcat &&
            !disabled ? (
              <ActionCell
                icon={
                  <div id="branch-mutations-delete-mutation">
                    <AnimatedIcon
                      icon="trash-icon"
                      className="h-8 md:h-6 w-8 md:w-6"
                    />
                  </div>
                }
                onClick={() => setDeleteTarget(e.row.original)}
              />
            ) : null}
          </div>
        ),
      },
    ];
    return baseColumns;
  }, [setEditTarget, setDeleteTarget, disabled]);

  const table = useGetCustomReactTable(
    mediaSequence.branchMutations.filter(
      (b) =>
        b.type !== 'BakeSubtitles' &&
        b.type !== 'AudioAdjustments' &&
        b.type !== 'GenerateSubtitles'
    ),
    -1,
    columns
  );

  return (
    <>
      <CustomTable table={table} hideHover hidePagination />
      {editTarget ? (
        <MediaSequenceBranchMutationUpdate
          id={editTarget?.id}
          mediaSequence={mediaSequence}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
          disabled={disabled}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          title="effekten"
          titlePropertyKey={'id'}
          getter={useGetMediaSequenceBranchMutation}
          deleter={useDeleteMediaSequenceBranchMutation}
        />
      ) : null}
    </>
  );
};

const describeMutation = (mutation: MediaSequenceBranchMutationResponse) => {
  const description = () => {
    switch (mutation.type) {
      case EBranchMutationType.ResizeAndConcat:
        if (!mutation.resizing?.resizeMode) return;
        return (
          <div>
            <p>{resizeTranslate(mutation.resizing.resizeMode)}</p>
          </div>
        );
      case EBranchMutationType.GenerateSubtitles:
        return <div>{/* Not much to say */}</div>;
      case EBranchMutationType.AddBackgroundMusic:
        if (!mutation.addBackgroundMusic) return;
        return <div>{/* TODO: Maybe add name of the asset? */}</div>;
      case EBranchMutationType.AddDynamicOverlay:
        return (
          <div>
            Vises efter {mutation.addDynamicOverlay?.delayInSeconds} sekund(er).
          </div>
        );
      case EBranchMutationType.AddWatermark:
        if (!mutation.addWatermark) return;
        return (
          <div>
            {/* TODO: Maybe add name of the asset? */}
            <p>{positionTranslate(mutation.addWatermark.position)}</p>
          </div>
        );
      case EBranchMutationType.AudioAdjustments:
        return <div>{/* Not much to say */}</div>;
    }
  };

  return description();
};
