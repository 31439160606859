import {
  AssetSimpleResponse,
  EAssetType,
  EPosition,
  MediaSequenceAssetResponse,
} from 'api/core';
import { VideoPreviewWithTrim } from 'components/Video/VideoPreviewWithTrim';
import { twMerge } from 'tailwind-merge';

interface ImageOverlayProps {
  asset?: MediaSequenceAssetResponse;
  overlayAsset?: AssetSimpleResponse;
  position?: EPosition;
  widthPercentage?: number;
  marginPercentageX?: number;
  marginPercentageY?: number;
}

export const ImageOverlayPreview = ({
  asset,
  overlayAsset,
  position,
  widthPercentage,
  marginPercentageX,
  marginPercentageY,
}: ImageOverlayProps) => {
  if (!overlayAsset) return null;

  const assetTrimMutation = asset?.mutations.find(
    (e) => e.trimVideo !== undefined
  )?.trimVideo;

  // Custom class to handle positioning and size of the overlay
  const overlayStyle = {
    width: widthPercentage ? `${widthPercentage}%` : '100%',
    // Handle horizontal positioning with translation for centered positions
    ...(position === EPosition.TopCenter || position === EPosition.BottomCenter
      ? {
          left: '50%',
          transform: 'translateX(-50%)',
          marginLeft: marginPercentageX ? `${marginPercentageX}%` : '0%',
        }
      : position === EPosition.TopRight || position === EPosition.BottomRight
        ? {
            marginRight: marginPercentageX ? `${marginPercentageX}%` : '0%',
          }
        : {
            marginLeft: marginPercentageX ? `${marginPercentageX}%` : '0%',
          }),
    // Handle vertical margins
    ...(position === EPosition.BottomLeft ||
    position === EPosition.BottomCenter ||
    position === EPosition.BottomRight
      ? {
          marginBottom: marginPercentageY ? `${marginPercentageY}%` : '0%',
        }
      : {
          marginTop: marginPercentageY ? `${marginPercentageY}%` : '0%',
        }),
  };

  return (
    <>
      <div className="overflow-hidden relative w-full h-full">
        {/* If no asset is passed then just overlay a fixed box with background */}
        {!asset ? <div className="w-full h-56 bg-green-500"></div> : null}

        {asset?.asset ? (
          <>
            {/* Original file */}
            {asset.asset.type === EAssetType.Video ? (
              <VideoPreviewWithTrim
                asset={asset.asset}
                trimStart={assetTrimMutation?.trimStartSeconds}
                trimEnd={assetTrimMutation?.trimEndSeconds}
                autoPlay
                loop
                muted
                className="w-full h-full"
              />
            ) : null}
            {asset.asset.type === EAssetType.Image ? (
              <img
                src={asset.asset.url}
                alt={asset.asset.originalFileName}
                className="w-full h-full"
              />
            ) : null}
          </>
        ) : null}

        {/* Overlay file */}
        <img
          src={overlayAsset.url}
          alt={overlayAsset.originalFileName}
          style={overlayStyle}
          className={twMerge(
            'absolute',
            position === EPosition.BottomCenter &&
              'bottom-0 mx-auto left-0 right-0',
            position === EPosition.BottomLeft && 'bottom-0 left-0',
            position === EPosition.BottomRight && 'bottom-0 right-0',
            position === EPosition.TopCenter && 'top-0 mx-auto left-0 right-0',
            position === EPosition.TopLeft && 'top-0 left-0',
            position === EPosition.TopRight && 'top-0 right-0'
          )}
        />
      </div>
      {!asset ? (
        <div className="mt-2">
          <p>
            Den grønne boks repræsenterer videoen, så du kan se hvor dit
            vandmærke kommer til at ligge på det endelige resultat.
          </p>
        </div>
      ) : null}
    </>
  );
};
