import { useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from 'App';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  OrganizationCreateRequest,
  OrganizationsApi,
  OrganizationUpdateRequest,
} from './core';

export const OrganizationApiKeys = {
  GET_ORGS: 'organizations',
  GET_ORG: 'organization',
  GET_PUBLIC_ORG: 'public-organization',
};

export const client = new OrganizationsApi(new CoreBaseConfiguration());

export const useGetOrganizations = (state?: TableQueryState) => {
  return useQuery({
    queryKey: [OrganizationApiKeys.GET_ORGS, state],
    queryFn: () =>
      client.organizationsGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
  });
};

export const useGetOrganization = (id?: string) => {
  return useQuery({
    queryKey: [OrganizationApiKeys.GET_ORG, id],
    queryFn: () => client.organizationsIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useGetPublicOrganizationInformation = (query?: string) => {
  return useQuery({
    queryKey: [OrganizationApiKeys.GET_PUBLIC_ORG, query],
    queryFn: () =>
      client.organizationsPublicInformationGet({ query: query ?? '' }),
    enabled: query !== undefined,
  });
};

export const useCreateOrganization = () => {
  return useMutation({
    mutationFn: ({
      organizationCreateRequest,
    }: {
      organizationCreateRequest: OrganizationCreateRequest;
    }) => client.organizationsPost({ organizationCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [OrganizationApiKeys.GET_ORGS],
      });
      toast.success(`Organisationen '${e.name}' er blevet oprettet`, {
        toastId: 'createOrganization',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke oprette organisationen');
    },
  });
};

export const useUpdateOrganization = () => {
  return useMutation({
    mutationFn: ({
      id,
      organizationUpdateRequest,
    }: {
      id: string;
      organizationUpdateRequest: OrganizationUpdateRequest;
    }) => client.organizationsIdPut({ id, organizationUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [OrganizationApiKeys.GET_ORGS],
      });
      queryClient.invalidateQueries({
        queryKey: [OrganizationApiKeys.GET_ORG, e.id],
      });
      toast.success(`Organisationen '${e.name}' er blevet opdateret`, {
        toastId: 'updateOrganization',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke opdatere organisationen');
    },
  });
};

export const useDeleteOrganization = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.organizationsIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [OrganizationApiKeys.GET_ORGS],
      });
      queryClient.invalidateQueries({
        queryKey: [OrganizationApiKeys.GET_ORG, e.id],
      });
      toast.success(`Organisationen '${e.name}' er blevet slettet`, {
        toastId: 'deleteOrganization',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke slette organisationen');
    },
  });
};
