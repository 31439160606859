import { ColumnDef } from '@tanstack/react-table';
import {
  EPermission,
  OrganizationResponse,
  OrganizationResponsePagedData,
} from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';

import { useMemo, useState } from 'react';
import { OrganizationUpdate } from './OrganizationUpdate';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import {
  useDeleteOrganization,
  useGetOrganization,
} from 'api/useOrganizationsApi';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';

interface TableProps {
  data?: OrganizationResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const OrganizationsTable = ({
  data,
  isFetching,
  queryState,
}: TableProps) => {
  const [editTarget, setEditTarget] = useState<OrganizationResponse | null>(
    null
  );
  const [deleteTarget, setDeleteTarget] = useState<OrganizationResponse | null>(
    null
  );

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<OrganizationResponse>[] = [
      {
        accessorKey: 'name',
        header: 'Navn',
      },
      {
        accessorKey: 'visualIdentity.name',
        header: 'Visuel identitet',
      },
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              permissions={[EPermission.OrganizationWrite]}
              icon={
                <AnimatedIcon
                  icon="pencil-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            <ActionCell
              permissions={[EPermission.OrganizationDelete]}
              icon={
                <AnimatedIcon
                  icon="trash-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setDeleteTarget(e.row.original)}
            />
          </div>
        ),
      },
    ];
    return baseColumns;
  }, [setEditTarget, setDeleteTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {editTarget ? (
        <OrganizationUpdate
          id={editTarget?.id}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetOrganization}
          deleter={useDeleteOrganization}
        />
      ) : null}
    </>
  );
};
