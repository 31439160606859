import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { AuthProtectedRoute } from 'auth/AuthProtectedRoute';
import { MobileNavigation } from './MobileNavigation';
import { useIsMobile } from 'utils/useIsMobile';

export const AdminLayout = () => {
  const isMobile = useIsMobile();
  const [isSidebarOpen, setSidebarOpen] = useState(!isMobile);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    setSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <AuthProtectedRoute>
      <Header toggleSidebar={toggleSidebar} />
      <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setSidebarOpen}>
        <Outlet />
      </Sidebar>
      <MobileNavigation setIsSidebarOpen={setSidebarOpen} />
      <div id="modal-root"></div>
      <div id="loading-root"></div>
    </AuthProtectedRoute>
  );
};
