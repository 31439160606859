import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { ErrorComponent } from 'components/Error';
import { MutateEntity } from './MutateEntity';

interface DeleteEntityByIdProps<T> {
  id?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onDeleted?: (result: T) => void;
  onClosed?: () => void;
  title?: string;
  titlePropertyKey?: keyof T;
  getter: (id?: string) => UseQueryResult<T, Error>;
  deleter: () => UseMutationResult<T, Error, { id: string }, unknown>;
  disabled?: boolean;
  tooltip?: string;
}

export function DeleteEntityById<T>({
  id,
  trigger,
  isInitialOpen,
  onDeleted,
  onClosed,
  title,
  titlePropertyKey,
  getter,
  deleter,
  disabled,
  tooltip,
}: DeleteEntityByIdProps<T>) {
  const { data, isLoading, isError } = getter(id);

  if (!id || isLoading) return null;
  if (isError || !data) return <ErrorComponent />;

  const entityTitle = title
    ? title
    : titlePropertyKey
      ? data[titlePropertyKey]
      : 'objektet';

  return (
    <MutateEntity
      trigger={trigger}
      isInitialOpen={isInitialOpen}
      onMutate={onDeleted}
      onClosed={onClosed}
      title={`Slet ${entityTitle}?`}
      description={`Er du sikker på du vil slette '${entityTitle}'?`}
      setter={deleter}
      mutationVariables={{ id }}
      hidden={disabled}
      tooltip={tooltip}
    />
  );
}
