import { useQuery } from '@tanstack/react-query';

import { SessionsApi } from './core';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { CoreBaseConfiguration } from './BaseConfiguration';

export const ApiKeys = {
  GET_PERSONAL_SESSIONS: 'personalSessions',
  GET_ORGANIZATION_SESSION: 'organizationSessions',
};

const client = new SessionsApi(new CoreBaseConfiguration());

export const useGetOrganizationTickets = (state?: TableQueryState) => {
  return useQuery({
    queryKey: [ApiKeys.GET_ORGANIZATION_SESSION, state],
    queryFn: () =>
      client.sessionsOrganizationStatisticsGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
      }),
  });
};
