import { Calendar, dateFnsLocalizer, ToolbarProps } from 'react-big-calendar';
import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';
import { startOfWeek } from 'date-fns/startOfWeek';
import { getDay } from 'date-fns/getDay';
import { da } from 'date-fns/locale/da';
import { Event } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { PublicationResponse } from 'api/core';
import { useEffect } from 'react';
// @ts-expect-error Toolbar is not exported by react-big-calendar
import Toolbar from 'react-big-calendar/lib/Toolbar';

const locales = {
  da: da,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface EventCalendarProps {
  calendarStyle?: React.CSSProperties;
  events: Event[];
  onEventDoubleClick: (publication: PublicationResponse) => void;
  onDateRangeChange: (startDate: Date, endDate: Date) => void;
}

export const EventCalendar = ({
  calendarStyle = { height: 500 },
  events,
  onEventDoubleClick,
  onDateRangeChange,
}: EventCalendarProps) => {
  const handleEventDoubleClick = (event: Event) => {
    if (!event.resource) return;
    onEventDoubleClick(event.resource as PublicationResponse);
  };

  const handleRangeChange = (range: Date[] | { start: Date; end: Date }) => {
    const adjustEndDate = (date: Date): Date => {
      const adjustedDate = new Date(date);
      adjustedDate.setDate(adjustedDate.getDate() + 1);
      return adjustedDate;
    };

    if (Array.isArray(range)) {
      const [firstDate, lastDate] = [range[0], range[range.length - 1]];
      onDateRangeChange(firstDate, adjustEndDate(lastDate));
    } else if ('start' in range && 'end' in range) {
      const { start: startDate, end: endDate } = range;
      onDateRangeChange(startDate, adjustEndDate(endDate));
    }
  };

  // https://github.com/jquense/react-big-calendar/issues/1752#issuecomment-761051235
  // The calendar does not provide any way to receive the range of dates that
  // are visible except when they change. This is the cleanest way I could find
  // to extend it to provide the _initial_ range (`onView` calls `onRangeChange`).
  const InitialRangeChangeToolbar = (props: ToolbarProps) => {
    useEffect(() => {
      props.onView(props.view);
    }, [props]);

    return <Toolbar {...props} />;
  };

  return (
    <Calendar
      culture="da"
      components={{ toolbar: InitialRangeChangeToolbar }}
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      className="custom-calendar"
      style={calendarStyle}
      onDoubleClickEvent={handleEventDoubleClick}
      onRangeChange={handleRangeChange}
      views={['month', 'week', 'day']}
      messages={{
        month: 'Måned',
        week: 'Uge',
        day: 'Dag',
        today: 'I dag',
        previous: 'Forrige',
        next: 'Næste',
        showMore: (total) => `+${total} flere`,
      }}
    />
  );
};
