import { AssetSimpleResponse, EImageToVideoMode } from 'api/core';
import { twMerge } from 'tailwind-merge';

interface ImageToVideoPreviewProps {
  asset: AssetSimpleResponse;
  mode: EImageToVideoMode;
}

export const ImageToVideoPreview = ({
  asset,
  mode,
}: ImageToVideoPreviewProps) => {
  return (
    <div className="overflow-hidden relative w-full h-full">
      <img
        src={asset.url}
        alt={asset.originalFileName}
        className={twMerge(
          'object-cover top-0 left-0 right-0 bottom-0 w-full h-full',
          mode === EImageToVideoMode.PanLeftToRight && 'animate-panRight',
          mode === EImageToVideoMode.PanTopLeftToBottomRight &&
            'animate-panDiagonal'
        )}
      />
    </div>
  );
};
