import {
  EAssetMutationType,
  EAssetSource,
  EAssetType,
  EBranchMutationType,
  ECaseState,
  EFieldType,
  EFieldVisibility,
  EImageToVideoMode,
  EMediaSequenceState,
  EPosition,
  EPublicationState,
  EResizeMode,
  EServiceTicketState,
  ETranscriptionState,
  EUpdateType,
} from 'api/core';

const UNKNOWN_TYPE = 'Ukendt type';

export const publicationStateTranslate = (state: EPublicationState) => {
  switch (state) {
    case EPublicationState.Draft:
      return 'Kladde';
    case EPublicationState.Scheduled:
      return 'Planlagt';
  }
};

export const caseStateTranslate = (state?: ECaseState) => {
  switch (state) {
    case ECaseState.Available:
      return 'Ledig';
    case ECaseState.Unavailable:
      return 'Udlejet';
    case ECaseState.Reserved:
      return 'Reserveret';
    case ECaseState.Deactivated:
      return 'Deaktiveret';
    default:
      return UNKNOWN_TYPE;
  }
};

export const fieldTypeTranslate = (state: EFieldType) => {
  switch (state) {
    case EFieldType.Text:
      return 'Tekst';
    case EFieldType.Area:
      return 'Areal';
    case EFieldType.SubsidyArea:
      return 'Boligstøtte areal';
    case EFieldType.Boolean:
      return 'Boolsk udtryk (ja/nej)';
    case EFieldType.Currency:
      return 'Valuta';
    case EFieldType.Date:
      return 'Dato';
    case EFieldType.Number:
      return 'Tal';
    case EFieldType.Rent:
      return 'Husleje';
    case EFieldType.HousingType:
      return 'Boligtype';
    case EFieldType.Longtext:
      return 'Lang tekst';
    case EFieldType.LongtextAdSocialMedia:
      return 'Lang tekst til sociale medier';
    case EFieldType.LongtextAdWebsite:
      return 'Lang tekst til web';
    case EFieldType.LongtextAdWindow:
      return 'Lang tekst til vindue';
    default:
      return UNKNOWN_TYPE;
  }
};

export const fieldVisibilityTranslate = (state: EFieldVisibility) => {
  switch (state) {
    case EFieldVisibility.AlwaysHidden:
      return 'Altid skjult';
    case EFieldVisibility.AlwaysVisible:
      return 'Altid synlig';
    case EFieldVisibility.DesktopOnly:
      return 'Kun synlig på desktop';
    case EFieldVisibility.MobileOnly:
      return 'Kun synlig på mobil';
    default:
      return UNKNOWN_TYPE;
  }
};

// TODO: Move rest of the enums here
export const assetMutationTranslate = (type: EAssetMutationType) => {
  switch (type) {
    case EAssetMutationType.ColorAdjustments:
      return 'Farvejustering';
    case EAssetMutationType.TrimVideo:
      return 'Klip video';
    case EAssetMutationType.ImageToVideo:
      return 'Billede til video';
    case EAssetMutationType.ImageOverlay:
      return 'Billede overlejring';
    case EAssetMutationType.SoundEffect:
      return 'Lydeffekt';
    case EAssetMutationType.AddDynamicOverlay:
      return 'Dynamisk overlejring';
    case EAssetMutationType.None:
      return 'Ingen';
    default:
      return UNKNOWN_TYPE;
  }
};

export const branchMutationTranslate = (type: EBranchMutationType) => {
  switch (type) {
    case EBranchMutationType.BakeSubtitles:
      return 'Indsæt undertekster';
    case EBranchMutationType.AddBackgroundMusic:
      return 'Baggrundsmusik';
    case EBranchMutationType.AddWatermark:
      return 'Vandmærke';
    case EBranchMutationType.AudioAdjustments:
      return 'Lydjustering';
    case EBranchMutationType.GenerateSubtitles:
      return 'Generer undertekster';
    case EBranchMutationType.ResizeAndConcat:
      return 'Størrelsestilpasning og sammensætning';
    case EBranchMutationType.AddDynamicOverlay:
      return 'Dynamisk overlejring';
    default:
      return UNKNOWN_TYPE;
  }
};

export const imageToVideoModeTranslate = (mode: EImageToVideoMode) => {
  switch (mode) {
    case EImageToVideoMode.PanLeftToRight:
      return 'Venstre mod højre';
    case EImageToVideoMode.PanTopLeftToBottomRight:
      return 'Venstre mod nederst til højre';
    case EImageToVideoMode.Still:
      return 'Stillebillede';
    case EImageToVideoMode.ZoomIn:
      return 'Zoom ind';
    case EImageToVideoMode.KenBurns:
      return 'Ken Burns-effekten';
    default:
      return UNKNOWN_TYPE;
  }
};

export const positionTranslate = (mode: EPosition) => {
  switch (mode) {
    case EPosition.TopLeft:
      return 'Øverst til venstre';
    case EPosition.TopCenter:
      return 'Øverst i midten';
    case EPosition.TopRight:
      return 'Øverst til højre';
    case EPosition.BottomLeft:
      return 'Nederst til venstre';
    case EPosition.BottomCenter:
      return 'Nederst i midten';
    case EPosition.BottomRight:
      return 'Nederst til højre';
    case EPosition.FullScreen:
      return 'Hele skærmen';
    default:
      return UNKNOWN_TYPE;
  }
};

export const resizeTranslate = (mode: EResizeMode) => {
  switch (mode) {
    case EResizeMode.Crop:
      return 'Crop / beskær';
    case EResizeMode.CropWithBlurredPadding:
      return 'Crop med evt. sløret letterbox';
    case EResizeMode.LetterboxPadding:
      return 'Letterbox';
    case EResizeMode.Forced:
      return 'Tvungen';
    default:
      return UNKNOWN_TYPE;
  }
};

export const mediaSequenceStateTranslate = (state: EMediaSequenceState) => {
  switch (state) {
    case EMediaSequenceState.Draft:
      return 'Kladde';
    case EMediaSequenceState.Processed:
      return 'Færdig';
    case EMediaSequenceState.Processing:
      return 'I gang';
    case EMediaSequenceState.Failed:
      return 'Fejlet';
    default:
      return UNKNOWN_TYPE;
  }
};

export const transcriptionStateTranslate = (state?: ETranscriptionState) => {
  switch (state) {
    case ETranscriptionState.NotApplicable:
      return 'Ikke relevant';
    case ETranscriptionState.Approved:
      return 'Godkendt';
    case ETranscriptionState.Generated:
      return 'Genereret';
    case ETranscriptionState.Pending:
      return 'Afventer';
    default:
      return UNKNOWN_TYPE;
  }
};

export const updateTypeTranslate = (state: EUpdateType) => {
  switch (state) {
    case EUpdateType.Failed:
      return 'Fejlet';
    case EUpdateType.Invoked:
      return 'Påbegyndt';
    case EUpdateType.Processed:
      return 'Færdiggjort';
    case EUpdateType.ProcessedWithMissingFrames:
      return 'Færdiggjort (med manglende frames)';
    case EUpdateType.Processing:
      return 'Påbegyndt';
    case EUpdateType.Resumed:
      return 'Genoptaget';
    default:
      return UNKNOWN_TYPE;
  }
};

export const assetTypeTranslate = (type: EAssetType) => {
  switch (type) {
    case EAssetType.Image:
      return 'Billede';
    case EAssetType.Video:
      return 'Video';
    case EAssetType.Audio:
      return 'Lyd';
    case EAssetType.Document:
      return 'Dokument';
    case EAssetType.Other:
      return 'Andet';
    default:
      return UNKNOWN_TYPE;
  }
};

export const assetSourceTranslate = (type: EAssetSource) => {
  switch (type) {
    case EAssetSource.Unknown:
      return 'Ukendt';
    case EAssetSource.SystemSeeded:
      return 'Systemfil';
    case EAssetSource.UserUploaded:
      return 'Bruger uploadet';
    case EAssetSource.MediaOrbitGeneated:
      return 'Smart Video genereret';
    case EAssetSource.ServiceTicket:
      return 'Support sag';
  }
};

export const serviceTicketStateTranslate = (type: EServiceTicketState) => {
  switch (type) {
    case EServiceTicketState.AwaitingCustomer:
      return 'Afventer kunde';
    case EServiceTicketState.AwaitingSupport:
      return 'Afventer support';
    case EServiceTicketState.AwaitingRelease:
      return 'Afventer frigivelse';
    case EServiceTicketState.Deleted:
      return 'Slettet';
    case EServiceTicketState.Done:
      return 'Færdig';
    default:
      return UNKNOWN_TYPE;
  }
};
