/* eslint-disable react/prop-types */

import {
  AssetSimpleResponse,
  EAssetType,
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationUpdateRequest,
} from 'api/core';
import { VideoPreviewWithTrim } from 'components/Video/VideoPreviewWithTrim';
import { getTrackBackground, Range } from 'react-range';
import { ListRestartIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface TrimVideoProps {
  asset: AssetSimpleResponse;
}

export const TrimVideo = ({ asset }: TrimVideoProps) => {
  const {
    watch,
    setValue,
    formState: { disabled },
  } = useFormContext<
    | MediaSequenceAssetMutationCreateRequest
    | MediaSequenceAssetMutationUpdateRequest
  >();

  const initialTrimStart = watch('trimVideo.trimStartSeconds') ?? 0;
  const initialTrimEnd = watch('trimVideo.trimEndSeconds') ?? 9999;

  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [values, setValues] = useState<[number, number]>([0, 0]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = document.createElement('video');
    video.src = asset.url;
    video.onloadedmetadata = () => {
      const duration = Math.ceil(video.duration * 10) / 10;
      setVideoDuration(duration);
      setIsLoading(false);

      const start = Math.min(Math.max(initialTrimStart, 0), duration);
      const end = Math.min(initialTrimEnd, duration);
      setValues([start, end]);
    };
  }, [asset, initialTrimStart, initialTrimEnd]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      if (video.currentTime < values[0] || video.currentTime > values[1]) {
        video.currentTime = values[0];
        video.play();
      }
    };

    const handlePlay = () => {
      if (video.currentTime < values[0] || video.currentTime > values[1]) {
        video.currentTime = values[0];
      }
    };

    const handleSeeked = () => {
      if (video.currentTime < values[0]) {
        video.currentTime = values[0];
      } else if (video.currentTime > values[1]) {
        video.currentTime = values[1];
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    video.addEventListener('play', handlePlay);
    video.addEventListener('seeked', handleSeeked);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('play', handlePlay);
      video.removeEventListener('seeked', handleSeeked);
    };
  }, [values]);

  useEffect(() => {
    if (isNaN(values[0]) === undefined || values[1] === undefined) {
      setFormErrorMessage('Tid ikke valgt');
    } else if (values[0] === values[1]) {
      setFormErrorMessage('Start og slut kan ikke være det samme');
    } else if (values[0] === 0 && values[1] === videoDuration) {
      setFormErrorMessage('Tid ikke valgt');
    } else {
      setFormErrorMessage(null);
    }
  }, [values, videoDuration]);

  if (asset.type !== EAssetType.Video || isLoading) return null;

  const handleInputChange = (index: number, value: number) => {
    if (isNaN(value)) return;
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues as [number, number]);
    if (index === 0) {
      setValue('trimVideo.trimStartSeconds', value);
    } else {
      setValue('trimVideo.trimEndSeconds', value);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col md:flex-row md:gap-4">
          <div className="form-control w-full">
            <label className="label">
              <div className="flex items-center">
                <span className="label-text">Start</span>
                {values[0] !== 0 && !disabled ? (
                  <ListRestartIcon
                    className="ml-2 cursor-pointer"
                    onClick={() => handleInputChange(0, 0)}
                  />
                ) : null}
              </div>
            </label>
            <input
              type="number"
              step="0.1"
              min="0"
              max={videoDuration}
              value={values[0]}
              onChange={(e) => handleInputChange(0, parseFloat(e.target.value))}
              className="input input-bordered w-full"
              disabled={disabled}
            />
          </div>
          <div className="form-control w-full">
            <label className="label">
              <div className="flex items-center">
                <span className="label-text">Slut</span>
                {values[1] !== videoDuration && !disabled ? (
                  <ListRestartIcon
                    className="ml-2 cursor-pointer"
                    onClick={() => handleInputChange(1, videoDuration)}
                  />
                ) : null}
              </div>
            </label>
            <input
              type="number"
              step="0.1"
              min="0"
              max={videoDuration}
              value={values[1]}
              onChange={(e) => handleInputChange(1, parseFloat(e.target.value))}
              className="input input-bordered w-full"
              disabled={disabled}
            />
          </div>
        </div>
      </div>

      <p className="text-sm text-gray-600">
        Du klipper videoen til ved at ændre start- og sluttidspunktet. Efter at
        have ændret dem kan du se resultatet i videoen nedenunder:
      </p>

      <VideoPreviewWithTrim
        asset={asset}
        trimStart={values[0]}
        trimEnd={values[1]}
      />

      <div className="hidden md:flex md:flex-col">
        <p className="text-sm text-gray-600">
          Alternativt kan du klippe videoen til ved at bruge værktøjet herunder:
        </p>
        <div className="flex w-full pt-6 pb-4 gap-7 items-center">
          <div className="text-xs text-gray-600">{formatTime(0)}</div>
          <Range
            disabled={disabled}
            step={0.1}
            min={0}
            max={videoDuration}
            values={values}
            onChange={(values: number[]) => {
              setValue('trimVideo.trimStartSeconds', values[0]);
              setValue('trimVideo.trimEndSeconds', values[1]);
              setValues(values as [number, number]);
            }}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                className="flex w-full content-center"
              >
                <div
                  ref={props.ref}
                  className="h-1 w-full rounded bg-gray-300"
                  style={{
                    background: getTrackBackground({
                      values,
                      colors: ['#ccc', 'var(--color-primary)', '#ccc'],
                      min: 0,
                      max: videoDuration,
                      rtl: false,
                    }),
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged, index }) => (
              <div
                {...props}
                className="relative h-10 w-10 rounded bg-white flex justify-center items-center shadow"
              >
                <div
                  className={`h-4 w-1 ${isDragged ? 'bg-primary' : 'bg-gray-300'}`}
                />
                {index === 0 ? (
                  <p className="absolute bottom-0 translate-y-full pt-1 text-xs text-gray-600">
                    {values[0]}
                  </p>
                ) : null}
                {index === 1 ? (
                  <p className="absolute bottom-0 translate-y-full pt-1 text-xs text-gray-600">
                    {values[1]}
                  </p>
                ) : null}
              </div>
            )}
          />
          <div className="text-xs text-gray-600">
            {formatTime(videoDuration)}
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-2">
        {formErrorMessage && (
          <span className="text-red-500">{formErrorMessage}</span>
        )}
      </div>
    </div>
  );
};
