import { Configuration as CoreConfiguration } from './core';

export class CoreBaseConfiguration extends CoreConfiguration {
  constructor() {
    super({
      credentials: 'include',
      basePath: process.env.REACT_APP_CORE_API_URL,
    });
  }
}
