import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { AngleForm } from './AngleForm';
import { useGetSections } from 'api/useSectionsApi';

interface AngleCreateProps {
  projectId: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const AngleCreate = ({
  projectId,
  trigger,
  isInitialOpen,
  onClosed,
}: AngleCreateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data: sections } = useGetSections(projectId);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  if (!sections) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opret vinkel"
        closeOnOutsideClick
        hideActionBar
      >
        <AngleForm
          projectId={projectId}
          allSections={sections.data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
