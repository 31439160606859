import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { MediaSequenceAssetForm } from './MediaSequenceAssetForm';

interface MediaSequenceAssetCreateProps {
  mediaSequenceId: string;
  projectId?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onSuccess?: () => void;
  onClosed?: () => void;
  inline?: boolean;
  disabled?: boolean;
}

export const MediaSequenceAssetCreate = ({
  mediaSequenceId,
  projectId,
  trigger,
  isInitialOpen,
  onSuccess,
  onClosed,
  disabled,
}: MediaSequenceAssetCreateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  const onFormSuccess = () => {
    onModalStateChange(false);
    onSuccess?.();
  };

  if (disabled) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Tilføj fil"
        closeOnOutsideClick
        hideActionBar
      >
        <MediaSequenceAssetForm
          projectId={projectId}
          mediaSequenceId={mediaSequenceId}
          onSuccess={onFormSuccess}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
