import { EPermission } from 'api/core';
import {
  useCreateFacebookIntegration,
  useDeleteExternalIntegration,
  useGetExternalIntegrations,
  useGetFacebookPageName,
} from 'api/useExternalIntegrationsApi';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { useEffect, useState } from 'react';

export const FacebookLogin = () => {
  const {
    data: externalIntegrations,
    isFetching: isFetchingExternalIntegrations,
  } = useGetExternalIntegrations();
  const { data: fbPageName } = useGetFacebookPageName();
  const { mutateAsync: addFacebookIntegration } =
    useCreateFacebookIntegration();
  const { mutateAsync: removeExternalIntegration } =
    useDeleteExternalIntegration();

  const [isOrgConnectedToFb, setIsOrgConnectedToFb] = useState(false);

  const scope =
    'email,pages_manage_posts,pages_show_list,pages_read_engagement';

  const [fbSdkLoaded, setFbSdkLoaded] = useState(false);
  const [fbSdkInitialized, setFbSdkInitialized] = useState(false);
  const [fbLoginStatus, setFbLoginStatus] = useState<FbLoginResponse | null>(
    null
  );
  const [fbSdkChecked, setFbSdkChecked] = useState(false);
  const [fbSdkBlocked, setFbSdkBlocked] = useState(false);

  useEffect(() => {
    if (fbSdkLoaded) return;
    if (isFetchingExternalIntegrations) return;

    if (document.getElementById('facebook-jssdk')) {
      setFbSdkLoaded(true);
      return;
    }

    const js = document.createElement('script');
    js.id = 'facebook-jssdk';
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    js.onload = () => {
      setFbSdkLoaded(true);
    };
    document.body.appendChild(js);
  }, [fbSdkLoaded, isFetchingExternalIntegrations]);

  useEffect(() => {
    if (!fbSdkLoaded || fbSdkInitialized) return;

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '407582965593789', // Replace with your Facebook app ID
        cookie: true,
        xfbml: true,
        version: 'v20.0',
      });

      window.FB.AppEvents.logPageView();
      setFbSdkInitialized(true);
    };

    setTimeout(() => {
      setFbSdkChecked(true);
    }, 5000);
  }, [fbLoginStatus, fbSdkLoaded, fbSdkInitialized]);

  useEffect(() => {
    if (!fbSdkChecked || fbSdkBlocked) return;
    setFbSdkBlocked(!fbSdkInitialized && fbLoginStatus?.status !== 'connected');
  }, [fbSdkInitialized, fbLoginStatus, fbSdkChecked, fbSdkBlocked]);

  useEffect(() => {
    if (!fbSdkInitialized) return;

    window.FB.getLoginStatus((response: FbLoginResponse) => {
      setFbLoginStatus(response);
    });
  }, [fbSdkInitialized]);

  useEffect(() => {
    if (
      !fbLoginStatus ||
      fbLoginStatus.status !== 'connected' ||
      !fbLoginStatus.authResponse
    )
      return;
    if (isOrgConnectedToFb) return;
    if (isFetchingExternalIntegrations) return;

    addFacebookIntegration({
      facebookCreateRequest: {
        token: {
          shortLivedUserAccessToken: fbLoginStatus.authResponse.accessToken,
          longLivedUserAccessToken: null,
          longLivedPageAccessToken: null,
          pageId: null,
        },
      },
    });
  }, [
    fbLoginStatus,
    addFacebookIntegration,
    isOrgConnectedToFb,
    isFetchingExternalIntegrations,
  ]);

  useEffect(() => {
    if (!externalIntegrations) return;
    const isOrgConnectedToFb = externalIntegrations.data.some(
      (ei) => ei.type === 'Facebook' && !ei.isExpired
    );
    setIsOrgConnectedToFb(isOrgConnectedToFb);
  }, [externalIntegrations]);

  const fbLogin = () => {
    window.FB.login(
      (response: FbLoginResponse) => {
        setFbLoginStatus(response);
      },
      { scope: scope }
    );
  };

  const fbLogout = () => {
    const id = externalIntegrations?.data.find(
      (ei) => ei.type === 'Facebook' && !ei.isExpired
    )?.id;

    const onCompleted = () => {
      if (id) {
        removeExternalIntegration({
          id: id,
        });
      }
      setFbLoginStatus(null);
    };

    if (!fbLoginStatus || !fbLoginStatus.authResponse) {
      onCompleted();
      return;
    }

    window.FB.logout(onCompleted);
  };

  return (
    <>
      {isFetchingExternalIntegrations ? (
        <div className="alert alert-info text-white mb-4">
          Henter eksterne integrationer
        </div>
      ) : isOrgConnectedToFb ? (
        <div className="alert alert-success text-white mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-info shrink-0 w-0 h-0"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div>
            <h3 className="font-bold">Tilknyttet!</h3>
            <div className="text-xs">
              Organisationen har tilknyttet Facebooksiden
              <span className="font-bold mx-2 inline-block">{fbPageName}</span>
            </div>
          </div>
          <PermissionProtectedComponent
            permissions={[EPermission.ExternalIntegrationDelete]}
          >
            <button className="btn btn-sm btn-primary mb-4" onClick={fbLogout}>
              Fjern Facebook
            </button>
          </PermissionProtectedComponent>
        </div>
      ) : fbSdkBlocked ? (
        <div className="alert alert-error text-white mb-4">
          Facebook SDK kunne ikke loade. Måske blokeret af Privacy Badger eller
          lignende
        </div>
      ) : fbLoginStatus?.status === 'connected' ? (
        <div className="alert alert-success text-white mb-4">
          Du er logget ind med Facebook, men den er ikke tilknyttet
          organisationen
        </div>
      ) : (
        <div className="alert alert-error text-white mb-4">
          Du er ikke logget ind med Facebook
          <PermissionProtectedComponent
            permissions={[EPermission.ExternalIntegrationWrite]}
          >
            <button className="btn btn-primary mb-4" onClick={fbLogin}>
              Tilknyt Facebook
            </button>
          </PermissionProtectedComponent>
        </div>
      )}
    </>
  );
};
