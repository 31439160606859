import { Tooltip } from 'components/Tooltip';
import { formatDistanceToNow, formatTimestamp } from 'utils/format/date';

interface DateTimeCellProps {
  value: Date;
}

export const DateTimeCell = ({ value }: DateTimeCellProps) => {
  return (
    <Tooltip tooltip={formatDistanceToNow(value)}>
      {formatTimestamp(value)}
    </Tooltip>
  );
};
