import { EPermission, StatResponse } from 'api/core';
import { useGetStats } from 'api/useStatsApi';
import { useAuth } from 'auth/AuthProvider';
import { AnimatedIcon, AnimatedIconKey } from 'components/Icon/AnimatedIcon';
import { Page } from 'layouts/Admin/Page';
import { MediaSequenceCreate } from 'pages/MediaOrbit/components/MediaSequence/MediaSequenceCreate';
import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export const DashboardView = () => {
  const { data } = useGetStats();

  if (!data) return null;

  return (
    <Page title="Oversigt">
      <StatGrid stats={data} />
    </Page>
  );
};

const StatGrid = ({ stats }: { stats: StatResponse[] }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7 gap-4">
      <GridItem
        mobileOnly
        permissions={[
          EPermission.ModuleMediaOrbit,
          EPermission.MediaSequenceWrite,
        ]}
      >
        <MediaSequenceCreate
          trigger={
            <button className="btn btn-primary w-full">
              Opret Smart Video
            </button>
          }
        />
      </GridItem>

      {stats.map((stat, i) => (
        <StatCard
          key={i}
          title={stat.title}
          description={stat.description}
          value={stat.value}
          link={stat.link}
          iconKey={stat.iconKey as AnimatedIconKey}
          actionRequired={stat.actionRequired}
        />
      ))}
    </div>
  );
};

interface GridItemProps {
  children: React.ReactNode;
  colSpan?: number;
  mobileOnly?: boolean;
  actionRequired?: boolean;
  permissions?: EPermission[];
}

const GridItem = ({
  children,
  colSpan,
  mobileOnly,
  actionRequired,
  permissions,
}: GridItemProps) => {
  const { hasPermissions } = useAuth();
  if (permissions && !hasPermissions(permissions)) return null;

  return (
    <div
      className={twMerge(
        'stat border shadow-md border-gray-300 rounded-xl',
        !colSpan && 'col-span-1',
        mobileOnly && 'md:hidden',
        colSpan && `col-span-${colSpan}`,
        actionRequired && 'border-2 border-warning'
      )}
    >
      {children}
    </div>
  );
};

interface StatCardProps {
  title: string;
  description: string;
  value: number;
  iconKey: AnimatedIconKey;
  link: string;
  actionRequired?: boolean;
}

const StatCard = ({
  title,
  description,
  value,
  iconKey,
  link,
  actionRequired,
}: StatCardProps) => {
  return (
    <NavLink to={link} className="py-1">
      <GridItem actionRequired={actionRequired}>
        <div className="stat-figure text-primary flex justify-center">
          <AnimatedIcon icon={iconKey} className="w-10 h-10" />
        </div>
        <div className="stat-title text-center">{title}</div>
        <div className="stat-value text-primary text-center">{value}</div>
        <div className="stat-desc text-center truncate">{description}</div>
      </GridItem>
    </NavLink>
  );
};
