import { useGetAuditLogs } from 'api/useAuditLogs';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { AuditLogsTable } from './components/AuditLogsTable';

export const AuditLogsView = () => {
  const { state } = useTableQueryState();
  const { data, isFetching: isFetchingFields } = useGetAuditLogs(state);

  return (
    <Page title="Audit Logs">
      <AuditLogsTable
        data={data}
        isFetching={isFetchingFields}
        queryState={state}
      />
    </Page>
  );
};
