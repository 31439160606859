import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'App';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  EstateOrbitSectionsApi,
  SectionCreateRequest,
  SectionUpdateRequest,
} from './core';

const SectionApiKeys = {
  GET_SECTIONS: 'sections',
  GET_SECTION: 'section',
};

const client = new EstateOrbitSectionsApi(new CoreBaseConfiguration());

export const useGetSections = (projectId?: string, state?: TableQueryState) => {
  return useQuery({
    queryKey: [SectionApiKeys.GET_SECTIONS, projectId, state],
    queryFn: () =>
      client.estateOrbitProjectsSectionsGet({
        projectId,
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
    enabled: projectId !== undefined,
  });
};

export const useGetSection = (id?: string) => {
  return useQuery({
    queryKey: [SectionApiKeys.GET_SECTION, id],
    queryFn: () => client.estateOrbitProjectsSectionsIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateSection = () => {
  return useMutation({
    mutationFn: ({
      projectId,
      sectionCreateRequest,
    }: {
      projectId: string;
      sectionCreateRequest: SectionCreateRequest;
    }) =>
      client.estateOrbitProjectsSectionsPost({
        projectId,
        sectionCreateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [SectionApiKeys.GET_SECTIONS],
      });
      toast.success(`Sektionen '${e.name}' er blevet oprettet`, {
        toastId: 'createSection',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke oprette sektionen');
    },
  });
};

export const useUpdateSection = () => {
  return useMutation({
    mutationFn: ({
      id,
      sectionUpdateRequest,
    }: {
      id: string;
      sectionUpdateRequest: SectionUpdateRequest;
    }) => client.estateOrbitProjectsSectionsIdPut({ id, sectionUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [SectionApiKeys.GET_SECTIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [SectionApiKeys.GET_SECTION, e.id],
      });
      toast.success(`Sektionen '${e.name}' er blevet opdateret`, {
        toastId: 'updateSection',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke opdatere sektionen');
    },
  });
};

export const useDeleteSection = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.estateOrbitProjectsSectionsIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [SectionApiKeys.GET_SECTIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [SectionApiKeys.GET_SECTION, e.id],
      });
      toast.success(`Sektionen '${e.name}' er blevet slettet`, {
        toastId: 'deleteSection',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke slette sektionen');
    },
  });
};
