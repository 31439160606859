import { AssetSimpleResponse, EAssetType } from 'api/core';
import './AudioPreview.css';
import { useEffect, useRef } from 'react';

interface AudioPreviewProps {
  asset: AssetSimpleResponse;
  volume: number;
  className?: string | undefined;
}

export const AudioPreview = ({
  asset,
  className,
  volume,
}: AudioPreviewProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  if (asset.type !== EAssetType.Audio) return null;

  return (
    <audio key={asset.id} className={className} controls ref={audioRef}>
      <source src={asset.url} />
      Your browser does not support the audio element.
    </audio>
  );
};
