import { useGetCases } from 'api/useCasesApi';
import { useGetProject } from 'api/useProjectsApi';
import { Page } from 'layouts/Admin/Page';
import { useParams } from 'react-router-dom';
import { useGetFields } from 'api/useFieldsApi';
import { CaseForm } from './components/Case/CaseForm';
import { CaseResponse, EPermission } from 'api/core';
import { MediaSequenceCreate } from 'pages/MediaOrbit/components/MediaSequence/MediaSequenceCreate';
import { useState } from 'react';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';

export const ProjectCase = () => {
  const { id } = useParams<{ id: string }>();
  const { data: project } = useGetProject(id, true);
  const { data: cases } = useGetCases(id);
  const { data: fields } = useGetFields(id);

  const [mediaSequenceCreateTarget, setMediaSequenceCreateTarget] =
    useState<CaseResponse | null>(null);

  if (!project || !cases?.data[0] || !fields || !project.singleCase)
    return null;

  return (
    <>
      <Page
        title={project?.name}
        breadcrumbs={[
          { name: 'Projekter' },
          { name: project?.name ?? '' },
          { name: 'Sag' },
        ]}
      >
        <div className="flex mt-2">
          <PermissionProtectedComponent
            permissions={[EPermission.ModuleMediaOrbit]}
          >
            <button
              onClick={() => setMediaSequenceCreateTarget(cases.data[0])}
              className="btn btn-success mr-2"
            >
              Opret video
            </button>
          </PermissionProtectedComponent>
        </div>
        <CaseForm
          targetCase={cases.data[0]}
          allFields={fields.data}
          allAssets={[]}
          isSingleCase
        />
      </Page>
      {mediaSequenceCreateTarget ? (
        <MediaSequenceCreate
          caseId={mediaSequenceCreateTarget.id}
          onClosed={() => setMediaSequenceCreateTarget(null)}
          isInitialOpen
        />
      ) : null}
    </>
  );
};
