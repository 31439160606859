import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useAuth } from 'auth/AuthProvider';
import { useGetTickets } from 'api/useServiceTicketApi';
import { ServiceTicketsTable } from './ServiceTicketsTable';
import { ServiceTicketCreate } from './ServiceTicketCreate';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';

export const ServiceTickets = () => {
  const { user } = useAuth();
  const { state } = useTableQueryState();
  const { data: tickets, isFetching } = useGetTickets(state);

  return (
    <Page
      title={user?.organization.name}
      breadcrumbs={[
        { name: user?.organization.name ?? '' },
        { name: 'Support' },
      ]}
    >
      <PermissionProtectedComponent
        permissions={[EPermission.ServiceTicketWrite]}
      >
        <ServiceTicketCreate
          trigger={
            <div className="flex justify-start py-4 w-full">
              <button className="btn btn-primary mr-2">
                Opret support ticket
              </button>
            </div>
          }
        />
      </PermissionProtectedComponent>
      <ServiceTicketsTable
        data={tickets}
        isFetching={isFetching}
        queryState={state}
      />
    </Page>
  );
};
