import {
  EEstateOrbitViewMode,
  EPermission,
  ProjectCreateRequest,
  ProjectResponse,
  ProjectUpdateRequest,
} from 'api/core';
import { useCreateProject, useUpdateProject } from 'api/useProjectsApi';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import {
  AddressSuggestion,
  AutocompleteAddress,
} from 'components/Form/AutocompleteAddress';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface ProjectFormProps {
  targetProject?: ProjectResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
  setTitle?: (title: string) => void;
}

export const ProjectForm = ({
  targetProject,
  onSuccess,
  onCancel,
  setTitle,
}: ProjectFormProps) => {
  const [isSingleProject, setIsSingleProject] = useState<boolean>(
    targetProject?.singleCase ?? true
  );
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateProject();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateProject();

  const navigate = useNavigate();

  const { register, handleSubmit, watch, setValue } = useForm<
    ProjectCreateRequest | ProjectUpdateRequest
  >({
    defaultValues: targetProject,
  });

  const [selectedSuggestion, setSelectedSuggestion] =
    useState<AddressSuggestion>();

  const estateOrbitEnabled = watch('estateOrbitEnabled');

  useEffect(() => {
    if (setTitle) {
      if (targetProject && !isSingleProject) setTitle('Rediger projekt');
      else if (targetProject && isSingleProject) setTitle('Rediger sag');
      else if (!targetProject && !isSingleProject) setTitle('Opret projekt');
      else if (!targetProject && isSingleProject) setTitle('Opret sag');
    }
  }, [isSingleProject, targetProject, setTitle]);

  const onSubmit = handleSubmit(async (result) => {
    if (targetProject) {
      await updateAsync({
        id: targetProject.id,
        projectUpdateRequest: result,
      });
    } else {
      const project = await createAsync({
        projectCreateRequest: {
          ...result,
          name:
            isSingleProject && selectedSuggestion
              ? selectedSuggestion.forslagstekst
              : result.name,
          singleCase: isSingleProject ?? false,
          dawaAddressId:
            isSingleProject && selectedSuggestion?.data
              ? selectedSuggestion.data.id
              : null,
        },
      });
      navigate(`/projects/${project.id}/${isSingleProject ? 'case' : 'cases'}`);
    }
    onSuccess?.();
  });

  return (
    <>
      {/* Only show if creation and organization has access to creating projects with multiple cases */}
      <PermissionProtectedComponent
        permissions={[EPermission.ProjectMultiCase]}
      >
        {!targetProject ? (
          <div className="w-full mt-6">
            <div className="flex">
              <div
                className={twMerge(
                  isSingleProject && 'bg-gray-200',
                  'w-1/2 flex flex-col items-center justify-center p-4 border border-gray-300 rounded-l-lg cursor-pointer hover:bg-gray-100'
                )}
                onClick={() => setIsSingleProject(true)}
              >
                <AnimatedIcon
                  icon="home-icon"
                  className="w-16 h-16 mb-2"
                  autoPlay
                />
                <p>Enkelt sag</p>
              </div>
              <div
                className={twMerge(
                  !isSingleProject && 'bg-gray-200',
                  'w-1/2 flex flex-col items-center justify-center p-4 border border-gray-300 rounded-r-lg cursor-pointer hover:bg-gray-100'
                )}
                onClick={() => setIsSingleProject(false)}
              >
                <AnimatedIcon
                  icon="building-icon"
                  className="w-16 h-16 mb-2"
                  autoPlay
                />
                <p>Flere sager</p>
              </div>
            </div>
          </div>
        ) : null}
      </PermissionProtectedComponent>

      {isSingleProject !== null ? (
        <form onSubmit={onSubmit} className="space-y-2">
          <AutocompleteAddress
            label={isSingleProject ? 'Adresse' : 'Projektnavn'}
            disabledInput={isSingleProject && !!targetProject}
            suggestAddresses={isSingleProject && !targetProject}
            registration={() => register('name', { required: true })}
            formWatch={watch('name') || ''}
            formSetValue={(value: string) => setValue('name', value)}
            onSuggestionSelectedFn={setSelectedSuggestion}
          />

          {!isSingleProject ? (
            <>
              <PermissionProtectedComponent
                permissions={[
                  EPermission.EstateOrbitToggle,
                  EPermission.ModuleBoligvaelger,
                ]}
              >
                <div className="form-control items-start">
                  <label className="cursor-pointer label">
                    <span className="label-text me-2">Estate Orbit</span>
                    <input
                      {...register('estateOrbitEnabled')}
                      type="checkbox"
                      className="toggle toggle-primary"
                    />
                  </label>
                </div>
              </PermissionProtectedComponent>

              {estateOrbitEnabled ? (
                <PermissionProtectedComponent
                  permissions={[
                    EPermission.ModuleBoligvaelger,
                    EPermission.ProjectWrite,
                  ]}
                >
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Estate Orbit design</span>
                    </label>
                    <select
                      {...register('estateOrbitViewMode', { required: true })}
                      className="select select-bordered"
                    >
                      <option value={EEstateOrbitViewMode.SideBySide}>
                        2-delt design
                      </option>
                      <option value={EEstateOrbitViewMode.OverUnder}>
                        Over-under design
                      </option>
                    </select>
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Script knap navn</span>
                    </label>
                    <input
                      {...register('estateOrbitCustomButtonText', {
                        required: false,
                      })}
                      type="text"
                      className="input input-bordered"
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Script knap kode</span>
                    </label>
                    <div className="form-control">
                      <textarea
                        {...register('estateOrbitCustomButtonScript', {
                          required: false,
                        })}
                        className="textarea textarea-bordered"
                        rows={3}
                      />
                    </div>
                  </div>
                </PermissionProtectedComponent>
              ) : null}
            </>
          ) : null}

          <div className="flex justify-center space-x-4 pt-4">
            <button
              className="btn btn-primary"
              disabled={
                isPendingCreate ||
                isPendingUpdate ||
                (isSingleProject && !selectedSuggestion)
              }
            >
              {targetProject ? 'Opdater' : 'Opret'}
            </button>
            {onCancel ? (
              <button type="button" className="btn" onClick={onCancel}>
                Annuller
              </button>
            ) : null}
          </div>
        </form>
      ) : null}
    </>
  );
};
