import { XIcon } from 'lucide-react';
import { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { twMerge } from 'tailwind-merge';

interface ControlledModalProps {
  showModal: (state: boolean) => void;
  isOpen: boolean;
  title?: string;
  children: ReactNode;
  closeOnOutsideClick?: boolean;
  className?: string;
  hideActionBar?: boolean;
}

export const ControlledModal = ({
  showModal,
  isOpen,
  title,
  children,
  closeOnOutsideClick,
  className,
  hideActionBar,
}: ControlledModalProps) => {
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      modalRef.current?.showModal();
    } else {
      modalRef.current?.close();
    }
  }, [modalRef, isOpen]);

  if (!isOpen) return null;

  const closeModal = () => showModal(false);

  const modalContent = (
    <dialog
      ref={modalRef}
      className="modal"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        if (e.key === 'Escape' && e.target === modalRef.current) {
          e.preventDefault();
          closeModal();
        }
      }}
      onCancel={(e) => {
        if (e.target !== modalRef.current) {
          return;
        }
        e.preventDefault();
        closeModal();
      }}
    >
      <div className={twMerge('modal-box bg-base-200', className)}>
        <div
          className={twMerge(
            'flex justify-end items-center',
            title && 'justify-between'
          )}
        >
          {title ? (
            <h3 className="font-bold text-lg overflow-x-hidden">{title}</h3>
          ) : null}
          <button
            className="btn btn-sm btn-circle btn-ghost"
            onClick={closeModal}
          >
            <XIcon size={18} />
          </button>
        </div>
        <div>{children}</div>
        {!hideActionBar ? (
          <div className="modal-action">
            <button className="btn" onClick={closeModal}>
              Luk
            </button>
          </div>
        ) : null}
      </div>
      {closeOnOutsideClick && (
        <div className="modal-backdrop" onClick={closeModal}></div>
      )}
    </dialog>
  );

  const modalRootElement = document.getElementById('modal-root');
  if (!modalRootElement) return null;

  return ReactDOM.createPortal(modalContent, modalRootElement);
};
