import { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from './AuthProvider';
import { FullScreenLoading } from 'components/Loading';

interface AuthProtectedRouteProps {
  children: ReactNode;
}

export const AuthProtectedRoute = ({ children }: AuthProtectedRouteProps) => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && !user && !location.pathname.includes('/login')) {
      navigate('/login');
    }
  }, [user, isLoading, navigate, location.pathname]);

  if (isLoading) {
    return <FullScreenLoading />;
  }

  return user ? <>{children}</> : null;
};
