/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EAssetMutationType = {
    None: 'None',
    ColorAdjustments: 'ColorAdjustments',
    TrimVideo: 'TrimVideo',
    ImageToVideo: 'ImageToVideo',
    ImageOverlay: 'ImageOverlay',
    SoundEffect: 'SoundEffect',
    AddDynamicOverlay: 'AddDynamicOverlay'
} as const;
export type EAssetMutationType = typeof EAssetMutationType[keyof typeof EAssetMutationType];


export function EAssetMutationTypeFromJSON(json: any): EAssetMutationType {
    return EAssetMutationTypeFromJSONTyped(json, false);
}

export function EAssetMutationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EAssetMutationType {
    return json as EAssetMutationType;
}

export function EAssetMutationTypeToJSON(value?: EAssetMutationType | null): any {
    return value as any;
}

