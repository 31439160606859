import { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { useDebounce } from 'utils/useDebounce';
import Select, { SingleValue } from 'react-select';

interface OptionType<T> {
  value: T;
  label?: string;
}

interface EntitySelectSingleAsyncProps<T, SearchParams> {
  onSuggestionSelected: (suggestion: T | null) => void;
  renderSuggestion: (suggestion: T) => React.ReactNode;
  useSearchFunction: (params: SearchParams) => UseQueryResult<T[], Error>;
  searchParams: SearchParams;
  initialValue?: T;
  placeholder?: string;
  stickyFirstOption?: OptionType<T>;
  onStickyFirstOptionClicked?: () => void;
  inDialog?: boolean;
  disabled?: boolean;
}

export function EntitySelectSingleAsync<T, SearchParams>({
  onSuggestionSelected,
  renderSuggestion,
  useSearchFunction,
  searchParams,
  initialValue,
  placeholder = 'Skriv for at søge...',
  stickyFirstOption,
  onStickyFirstOptionClicked,
  inDialog,
  disabled,
}: EntitySelectSingleAsyncProps<T, SearchParams>) {
  const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const paramsWithSearchTerm = {
    ...searchParams,
    searchTerm: debouncedSearchTerm,
  };
  const { data: suggestions, isLoading } =
    useSearchFunction(paramsWithSearchTerm);

  const [selectedOption, setSelectedOption] = useState<OptionType<T> | null>(
    initialValue ? { value: initialValue } : null
  );
  const [options, setOptions] = useState<OptionType<T>[]>([]);

  useEffect(() => {
    let formattedOptions = suggestions
      ? suggestions.map((suggestion) => ({
          value: suggestion,
        }))
      : [];

    // Insert the sticky option at the top if it exists
    if (stickyFirstOption) {
      formattedOptions = [stickyFirstOption, ...formattedOptions];
    }

    setOptions(formattedOptions);
  }, [suggestions, stickyFirstOption]);

  useEffect(() => {
    if (inDialog) {
      setMenuPortalTarget(document.querySelector('dialog'));
    } else {
      setMenuPortalTarget(document.querySelector('body'));
    }
  }, [inDialog]);

  useEffect(() => {
    if (initialValue) {
      setSelectedOption({ value: initialValue, label: '' });
    }
  }, [initialValue]);

  return (
    <div className="form-control w-full">
      <Select
        id="entity-select-single-async"
        inputId="entity-select-single-async-input"
        options={options}
        value={selectedOption}
        onInputChange={(value) => setSearchTerm(value)}
        onChange={(selectedOption: SingleValue<OptionType<T>>) => {
          if (
            stickyFirstOption &&
            selectedOption?.value === stickyFirstOption.value
          ) {
            onStickyFirstOptionClicked?.();
            return;
          }
          setSelectedOption(selectedOption);
          onSuggestionSelected(selectedOption?.value ?? null);
        }}
        placeholder={placeholder}
        isLoading={isLoading}
        isClearable
        noOptionsMessage={() => 'Ingen resultater fundet'}
        filterOption={null}
        formatOptionLabel={(option) => (
          <>{option.label ? option.label : renderSuggestion(option.value)}</>
        )}
        menuPortalTarget={menuPortalTarget}
        styles={{
          control: (base) => ({
            ...base,
            height: '3rem',
          }),
        }}
        menuPosition="fixed"
        isDisabled={disabled}
      />
    </div>
  );
}
