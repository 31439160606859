import { useQuery } from '@tanstack/react-query';

import { TranslationsApi } from './core';
import { CoreBaseConfiguration } from './BaseConfiguration';

const TranslationApiKeys = {
  GET_LANGUAGES: 'languages',
  GET_LANGUAGE: 'language',
  GET_LANGUAGE_TERMS: 'language-terms',
  GET_LANGUAGE_TERM_CONFIGURATIONS: 'language-term-configurations',
};

const client = new TranslationsApi(new CoreBaseConfiguration());

export const useGetLanguages = () => {
  return useQuery({
    queryKey: [TranslationApiKeys.GET_LANGUAGES],
    queryFn: () => client.translationsLanguagesGet(),
  });
};

export const useGetLanguage = (id?: number) => {
  return useQuery({
    queryKey: [TranslationApiKeys.GET_LANGUAGE, id],
    queryFn: () => client.translationsLanguageIdGet({ id: id ?? 0 }),
    enabled: id !== undefined,
  });
};

export const useGetLanguageTerms = (id?: number) => {
  return useQuery({
    queryKey: [TranslationApiKeys.GET_LANGUAGE_TERMS, id],
    queryFn: () => client.translationsTermsIdGet({ id: id ?? 0 }),
    enabled: id !== undefined,
  });
};

export const useGetLanguageTermConfigurations = () => {
  return useQuery({
    queryKey: [TranslationApiKeys.GET_LANGUAGE_TERM_CONFIGURATIONS],
    queryFn: () => client.translationsTermsConfigurationGet(),
  });
};
