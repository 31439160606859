import { AuthorizationRequest } from 'api/core';
import { useLogin } from 'api/useAuthApi';
import { KeyboardEvent } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';

export const LoginView = () => {
  const { mutateAsync, isPending } = useLogin();
  const { register, handleSubmit } = useForm<AuthorizationRequest>({
    defaultValues: {
      email: localStorage.getItem('email') || '',
    },
  });

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const onSubmit = handleSubmit(async (result) => {
    await mutateAsync({
      authorizationRequest: result,
    });
  });

  return (
    <form onSubmit={onSubmit} className="space-y-4">
      <div className="form-control">
        <label className="label">
          <span className="text-base label-text">Email</span>
        </label>
        <input
          {...register('email', { required: true })}
          type="text"
          placeholder="Email"
          className="w-full input input-bordered"
          onKeyDown={handleKeyPress}
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="text-base label-text">Adgangskode</span>
        </label>
        <input
          {...register('password', { required: true })}
          type="password"
          placeholder="Adgangskode"
          className="w-full input input-bordered"
          onKeyDown={handleKeyPress}
        />
      </div>

      <div>
        <button className="btn btn-primary btn-block" disabled={isPending}>
          Login
        </button>
      </div>

      <div className="divider"></div>

      <div className="flex justify-center">
        <NavLink to={'reset-password'} className="link link-hover" end>
          Glemt adgangskode
        </NavLink>
      </div>
    </form>
  );
};
