import {
  AssetSimpleResponse,
  EImageToVideoMode,
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationUpdateRequest,
} from 'api/core';
import { ImageToVideoPreview } from 'components/Image/ImageToVideoPreview';
import { useFormContext } from 'react-hook-form';
import { imageToVideoModeTranslate } from 'utils/enum-translate';

interface ImageToVideoProps {
  asset: AssetSimpleResponse;
}

export const ImageToVideo = ({ asset }: ImageToVideoProps) => {
  const { register, watch } = useFormContext<
    | MediaSequenceAssetMutationCreateRequest
    | MediaSequenceAssetMutationUpdateRequest
  >();

  return (
    <>
      <div className="form-control">
        <label className="label">
          <span className="label-text">Animation</span>
        </label>
        <select
          {...register('imageToVideo.mode', { required: true })}
          className="select select-bordered"
        >
          <option value={EImageToVideoMode.PanLeftToRight}>
            {imageToVideoModeTranslate(EImageToVideoMode.PanLeftToRight)}
          </option>
          <option value={EImageToVideoMode.PanTopLeftToBottomRight}>
            {imageToVideoModeTranslate(
              EImageToVideoMode.PanTopLeftToBottomRight
            )}
          </option>
          <option value={EImageToVideoMode.Still}>
            {imageToVideoModeTranslate(EImageToVideoMode.Still)}
          </option>
          {/* <option value={EImageToVideoMode.ZoomIn}>
          {imageToVideoModeTranslate(EImageToVideoMode.ZoomIn)}
        </option> */}
          {/* <option value={EImageToVideoMode.KenBurns}>
          {imageToVideoModeTranslate(EImageToVideoMode.KenBurns)}
        </option> */}
        </select>
      </div>
      <ImageToVideoPreview asset={asset} mode={watch('imageToVideo.mode')} />

      <div className="form-control">
        <label className="label">
          <span className="label-text">Varighed i sekunder</span>
        </label>
        <input
          {...register('imageToVideo.durationInSeconds', {
            required: true,
          })}
          type="number"
          min={0}
          max={30}
          className="input input-bordered"
        />
      </div>
    </>
  );
};
