import { useGetLanguages } from 'api/useTranslationsApi';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { LanguagesTable } from './components/LanguagesTable';

export const LanguagesView = () => {
  const { state } = useTableQueryState();
  const { data, isFetching } = useGetLanguages();

  return (
    <Page title="languages">
      <LanguagesTable
        data={data ?? []}
        isFetching={isFetching}
        queryState={state}
      />
    </Page>
  );
};
