import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetMediaSequences } from 'api/useMediaSequencesApi';
import { MediaSequencesTable } from './components/MediaSequence/MediaSequencesTable';
import { MediaSequenceCreate } from './components/MediaSequence/MediaSequenceCreate';
import { useIsMobile } from 'utils/useIsMobile';

export const MediaSequences = () => {
  const isMobile = useIsMobile();
  const { state } = useTableQueryState();
  const { data, isFetching: isFetchingFields } = useGetMediaSequences(state, {
    refetchInterval: isMobile ? undefined : 5000,
  });

  return (
    <Page
      title="Smart Video - Videoer"
      breadcrumbs={[{ name: 'Smart Video' }, { name: 'Videoer' }]}
    >
      <div className="flex justify-start py-2 pb-3 w-full">
        <MediaSequenceCreate
          trigger={<button className="btn btn-primary">Opret video</button>}
        />
      </div>
      <MediaSequencesTable
        data={data}
        isFetching={isFetchingFields}
        queryState={state}
      />
    </Page>
  );
};
