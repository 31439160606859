/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DynamicTemplateFieldCreateRequest } from './DynamicTemplateFieldCreateRequest';
import {
    DynamicTemplateFieldCreateRequestFromJSON,
    DynamicTemplateFieldCreateRequestFromJSONTyped,
    DynamicTemplateFieldCreateRequestToJSON,
} from './DynamicTemplateFieldCreateRequest';

/**
 * 
 * @export
 * @interface DynamicTemplateCreateRequest
 */
export interface DynamicTemplateCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateCreateRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateCreateRequest
     */
    html: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateCreateRequest
     */
    organizationId?: string | null;
    /**
     * 
     * @type {Array<DynamicTemplateFieldCreateRequest>}
     * @memberof DynamicTemplateCreateRequest
     */
    fields: Array<DynamicTemplateFieldCreateRequest>;
}

/**
 * Check if a given object implements the DynamicTemplateCreateRequest interface.
 */
export function instanceOfDynamicTemplateCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "html" in value;
    isInstance = isInstance && "fields" in value;

    return isInstance;
}

export function DynamicTemplateCreateRequestFromJSON(json: any): DynamicTemplateCreateRequest {
    return DynamicTemplateCreateRequestFromJSONTyped(json, false);
}

export function DynamicTemplateCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicTemplateCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'html': json['html'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'fields': ((json['fields'] as Array<any>).map(DynamicTemplateFieldCreateRequestFromJSON)),
    };
}

export function DynamicTemplateCreateRequestToJSON(value?: DynamicTemplateCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'html': value.html,
        'organizationId': value.organizationId,
        'fields': ((value.fields as Array<any>).map(DynamicTemplateFieldCreateRequestToJSON)),
    };
}

