import { useGetUser } from 'api/useUsersApi';
import { ControlledModal } from 'components/ControlledModal';
import { UserForm } from './UserForm';
import { useGetRoles } from 'api/useRolesApi';
import { useState } from 'react';

interface UserUpdateProps {
  id?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const UserUpdate = ({
  id,
  trigger,
  isInitialOpen,
  onClosed,
}: UserUpdateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data } = useGetUser(id);
  const { data: roles } = useGetRoles();

  if (!data || !roles) return null;

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opdater bruger"
        closeOnOutsideClick
        hideActionBar
      >
        <UserForm
          targetUser={data}
          allRoles={roles.data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
