import {
  QueryKey,
  QueryObserverOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { queryClient } from 'App';
import { useAuth } from 'auth/AuthProvider';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  EPermission,
  MediaSequenceCloneRequest,
  MediaSequenceCreateRequest,
  MediaSequenceResponse,
  MediaSequenceResponsePagedData,
  MediaSequencesApi,
  MediaSequencesIdTranscriptionPutRequest,
  MediaSequenceUpdateRequest,
} from './core';
import { MetaMappingsApiKeys } from './useMetaMappingsApi';

export const MediaSequenceApiKeys = {
  GET_MEDIA_SEQUENCES_LAST_VIEWED: 'media-sequences-last-viewed',
  GET_MEDIA_SEQUENCES_SEARCH: 'media-sequences-search',
  GET_MEDIA_SEQUENCES_TEMPLATES: 'media-sequences-templates',
  GET_MEDIA_SEQUENCE_ASSETS: 'media-sequence-assets',
  GET_MEDIA_SEQUENCE_UPDATES: 'media-sequence-updates',
  GET_MEDIA_SEQUENCES: 'media-sequences',
  GET_MEDIA_SEQUENCE: 'media-sequence',
  GET_MEDIA_SEQUENCE_PREVIEW: 'media-sequence-preview',
};

const client = new MediaSequencesApi(new CoreBaseConfiguration());

export const useSearchMediaSequences = ({
  searchTerm,
}: {
  searchTerm?: string;
}) => {
  return useQuery({
    queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_SEARCH, searchTerm],
    queryFn: () =>
      client.mediaSequencesSearchGet({
        size: 10,
        searchTerm,
      }),
    enabled: searchTerm !== undefined || searchTerm != '',
  });
};

export const useSearchMediaSequenceTemplates = () => {
  return useQuery({
    queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_TEMPLATES],
    queryFn: () => client.mediaSequencesTemplatesGet(),
  });
};

export const useGetMediaSequences = (
  state?: TableQueryState,
  options: Omit<
    QueryObserverOptions<
      MediaSequenceResponsePagedData,
      Error,
      MediaSequenceResponsePagedData,
      MediaSequenceResponsePagedData,
      QueryKey
    >,
    'queryKey'
  > = {}
) => {
  return useQuery<MediaSequenceResponsePagedData, Error>({
    queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES, state, options],
    queryFn: () =>
      client.mediaSequencesGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
    ...options,
  });
};

export const useGetLastViewedMediaSequences = (amount: number) => {
  const { hasPermissions } = useAuth();

  return useQuery({
    queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_LAST_VIEWED],
    queryFn: () =>
      client.mediaSequencesLastViewedGet({
        amount,
      }),
    enabled: hasPermissions([
      EPermission.ModuleMediaOrbit,
      EPermission.MediaSequenceRead,
    ]),
  });
};

export const useGetMediaSequencePreviewVideo = (id?: string) => {
  return useQuery({
    queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE_PREVIEW, id],
    queryFn: () =>
      client.mediaSequencesIdPreviewUrlGet({
        id: id ?? '',
      }),
    enabled: id !== undefined,
  });
};

export const useGetMediaSequence = (
  id?: string,
  options: Omit<
    QueryObserverOptions<
      MediaSequenceResponse,
      Error,
      MediaSequenceResponse,
      MediaSequenceResponse,
      QueryKey
    >,
    'queryKey'
  > = {},
  invalidateLastViewed?: boolean
) => {
  return useQuery<MediaSequenceResponse, Error>({
    queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, id],
    queryFn: async () => {
      const data = await client.mediaSequencesIdGet({ id: id ?? '' });
      if (invalidateLastViewed) {
        queryClient.invalidateQueries({
          queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_LAST_VIEWED],
        });
      }
      return data;
    },
    enabled: id !== undefined,
    ...options,
  });
};

export const useGetMediaSequenceAssets = (id: string) => {
  return useQuery({
    queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE_ASSETS, id],
    queryFn: () =>
      client.mediaSequencesIdAssetsGet({
        id: id,
      }),
  });
};

export const useGetMediaSequenceUpdates = (id?: string) => {
  return useQuery({
    queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE_UPDATES, id],
    queryFn: () =>
      client.mediaSequencesIdUpdatesGet({
        id: id ?? '',
      }),
    enabled: id !== undefined,
  });
};

export const useCreateMediaSequence = () => {
  return useMutation({
    mutationFn: ({
      mediaSequenceCreateRequest,
    }: {
      mediaSequenceCreateRequest: MediaSequenceCreateRequest;
    }) => client.mediaSequencesPost({ mediaSequenceCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES],
      });
      toast.success(`Redigeringen '${e.name}' er blevet oprettet`, {
        toastId: 'createMediaSequence',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke oprette videoen');
    },
  });
};

export const useUpdateMediaSequence = () => {
  return useMutation({
    mutationFn: ({
      id,
      mediaSequenceUpdateRequest,
    }: {
      id: string;
      mediaSequenceUpdateRequest: MediaSequenceUpdateRequest;
    }) => client.mediaSequencesIdPut({ id, mediaSequenceUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [MetaMappingsApiKeys.GET_META_MAPPINGS, e.id],
      });
      toast.success(`Redigeringen '${e.name}' er blevet opdateret`, {
        toastId: 'updateMediaSequence',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke opdatere videoen');
    },
  });
};

export const useDeleteMediaSequence = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.mediaSequencesIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.id],
      });
      toast.success(`Redigeringen '${e.name}' er blevet slettet`, {
        toastId: 'deleteMediaSequence',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke slette videoen');
    },
  });
};

export const useInvokeMediaSequence = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.mediaSequencesIdInvokePut({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.id],
      });
      toast.success(`Redigeringen '${e.name}' er blevet startet`, {
        toastId: 'invokeMediaSequence',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke starte videoen');
    },
  });
};

export const useUpdateMediaSequenceTranscription = () => {
  return useMutation({
    mutationFn: ({
      request,
    }: {
      request: MediaSequencesIdTranscriptionPutRequest;
    }) => client.mediaSequencesIdTranscriptionPut(request),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.id],
      });
      toast.success(`Undertekster på videoen '${e.name}' er blevet opdateret`, {
        toastId: 'updateMediaSequenceTranscription',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke opdatere underteksterne på videoen');
    },
  });
};

export const useApproveMediaSequenceTranscription = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.mediaSequencesIdApproveTranscriptionPut({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.id],
      });
      toast.success(`Undertekster til videoen '${e.name}' er blevet godkendt`, {
        toastId: 'approveMediaSequenceTranscription',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke godkende undertekster');
    },
  });
};

export const useCloneMediaSequence = () => {
  return useMutation({
    mutationFn: ({
      id,
      mediaSequenceCloneRequest,
    }: {
      id: string;
      mediaSequenceCloneRequest: MediaSequenceCloneRequest;
    }) => client.mediaSequencesIdClonePut({ id, mediaSequenceCloneRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES],
      });
      toast.success(`Redigeringen er blevet kopieret til '${e.name}'`, {
        toastId: 'cloneMediaSequence',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke kopiere videoen');
    },
  });
};

export const useRedoMediaSequence = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.mediaSequencesIdRedoPut({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCES],
      });
      navigate(`/media-sequences/${e.id}`);
    },
    onError: () => {
      toast.warn('Kunne ikke låse op for videoen');
    },
  });
};
