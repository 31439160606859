import { useEffect, useState } from 'react';
import {
  DynamicTemplateFieldResponse,
  EAssetType,
  MediaSequenceAssetResponse,
  MetaMappingResponse,
} from 'api/core';
import { VideoPreviewWithTrim } from 'components/Video/VideoPreviewWithTrim';

interface DynamicTemplatePreviewProps {
  html: string;
  fields?: DynamicTemplateFieldResponse[];
  dynamicTemplateFieldValues?: { [key: string]: string };
  metaMappings?: MetaMappingResponse[];
  asset?: MediaSequenceAssetResponse;
  showReplayButton?: boolean;
  previewAspectRatio?: number;
  opacity?: number;
}

export const DynamicTemplatePreview = ({
  html,
  fields,
  dynamicTemplateFieldValues,
  metaMappings,
  asset,
  showReplayButton,
  previewAspectRatio,
  opacity,
}: DynamicTemplatePreviewProps) => {
  const [showPreview, setShowPreview] = useState<boolean>(true);
  const [htmlResult, setHtmlResult] = useState<string>(html);

  const stringifiedTemplateFieldValues = JSON.stringify(
    dynamicTemplateFieldValues
  );

  useEffect(() => {
    if (!fields || !dynamicTemplateFieldValues) return;
    // Get templateKey from fields by pairing with dynamicTemplateFieldValues id
    const templateKeys = fields.map((field) => {
      const value = dynamicTemplateFieldValues[field.id];

      // Check if meta mapping exist that matches the template key
      const targetMetaMapping = metaMappings?.find(
        (metaMapping) => metaMapping.key === value
      );

      return {
        key: field.templateKey,
        value: targetMetaMapping?.value ?? value,
      };
    });

    // Replace templateKey with value in html
    let htmlResultTemp = html;
    templateKeys.forEach((key) => {
      htmlResultTemp = htmlResultTemp.replace(
        new RegExp(`{{${key.key}}}`, 'g'),
        key.value
      );
    });

    setHtmlResult(htmlResultTemp);
  }, [
    html,
    fields,
    dynamicTemplateFieldValues,
    stringifiedTemplateFieldValues,
    metaMappings,
  ]);

  const reRunPreview = () => {
    setShowPreview(false);
  };

  useEffect(() => {
    if (showPreview) return;
    setShowPreview(true);
  }, [showPreview]);

  const assetTrimMutation = asset?.mutations.find(
    (e) => e.trimVideo !== undefined
  )?.trimVideo;

  return (
    <>
      {showReplayButton ? (
        <div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={reRunPreview}
          >
            Afspil overlejring forfra
          </button>
        </div>
      ) : null}

      <div
        className="relative w-full"
        style={{ aspectRatio: previewAspectRatio }}
      >
        {asset?.asset.type === EAssetType.Video ? (
          <VideoPreviewWithTrim
            asset={asset.asset}
            trimStart={assetTrimMutation?.trimStartSeconds}
            trimEnd={assetTrimMutation?.trimEndSeconds}
            className="w-full h-full"
            style={{ zIndex: 1 }}
          />
        ) : null}

        {asset?.asset.type === EAssetType.Image ? (
          <img
            src={asset.asset.url}
            alt="Background"
            className="w-full h-full"
            style={{ aspectRatio: previewAspectRatio, zIndex: 1 }}
          />
        ) : null}

        {!asset ? (
          <img
            src="/media-orbit/dynamic-overlay-bg1.webp"
            alt="Background"
            className="w-full h-full"
            style={{ aspectRatio: previewAspectRatio, zIndex: 1 }}
          />
        ) : null}

        {showPreview ? (
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{ zIndex: 2 }}
          >
            <iframe
              className="border absolute top-0 left-0 w-full h-full"
              style={{ opacity: opacity || 1, zIndex: 3 }}
              srcDoc={htmlResult}
              sandbox="allow-same-origin allow-scripts"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
