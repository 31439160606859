import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  CaseResponse,
  CaseResponsePagedData,
  ECaseState,
  EPermission,
  FieldResponse,
} from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { BadgeCell } from 'components/Table/Cell/BadgeCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useMemo, useState } from 'react';
import { CaseUpdate } from './CaseUpdate';
import { useDeleteCase, useGetCase } from 'api/useCasesApi';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { MediaSequenceCreate } from 'pages/MediaOrbit/components/MediaSequence/MediaSequenceCreate';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { useAuth } from 'auth/AuthProvider';
import { formatFieldValue } from 'utils/format/field';
import { caseStateTranslate } from 'utils/enum-translate';

interface TableProps {
  projectId: string;
  data?: CaseResponsePagedData;
  fields: FieldResponse[];
  isFetching: boolean;
  queryState: TableQueryState;
}

export const CasesTable = ({
  projectId,
  data,
  fields,
  isFetching,
  queryState,
}: TableProps) => {
  const { hasPermissions } = useAuth();
  const [mediaSequenceCreateTarget, setMediaSequenceCreateTarget] =
    useState<CaseResponse | null>(null);
  const [editTarget, setEditTarget] = useState<CaseResponse | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<CaseResponse | null>(null);

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<CaseResponse>[] = [
      {
        accessorKey: 'address',
        header: 'Adresse',
      },
      {
        accessorKey: 'maskId',
        header: 'Maske ID',
      },
      {
        accessorKey: 'state',
        header: 'Status',
        cell: (e) => (
          <BadgeCell
            text={caseStateTranslate(e.row.original.state)}
            type={
              e.row.original.state === ECaseState.Available
                ? 'primary'
                : e.row.original.state === ECaseState.Deactivated
                  ? 'error'
                  : 'warning'
            }
          />
        ),
      },
      {
        accessorKey: 'assets',
        header: 'Filer',
        cell: (e) => e.row.original.assets.length,
        enableSorting: false,
      },
    ];

    const customFieldColumns = fields
      .sort((e) => e.order)
      .map((field) => ({
        id: field.id,
        cell: (e: CellContext<CaseResponse, unknown>) => {
          const caseField = e.row.original.caseFields.find(
            (cf) => cf.fieldId === field.id
          );
          if (!caseField) return '';
          return <>{formatFieldValue(caseField, [field])}</>;
        },
        header: field.name,
        enableSorting: false,
      }));

    const entityColumns: ColumnDef<CaseResponse>[] = [
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              permissions={[
                EPermission.ModuleMediaOrbit,
                EPermission.MediaSequenceWrite,
              ]}
              icon={
                <AnimatedIcon
                  icon="movie-edit-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setMediaSequenceCreateTarget(e.row.original)}
              tooltip="Opret Smart Video"
            />
            <ActionCell
              permissions={[
                EPermission.CaseModifyAddress,
                EPermission.CaseModifyAssets,
                EPermission.CaseModifyCustomFields,
                EPermission.CaseModifyMask,
                EPermission.CaseModifyState,
              ]}
              permissionScope="any"
              icon={
                <AnimatedIcon
                  icon="pencil-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            <ActionCell
              permissions={[EPermission.CaseDelete]}
              icon={
                <AnimatedIcon
                  icon="trash-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setDeleteTarget(e.row.original)}
            />
          </div>
        ),
      },
    ];

    return [...baseColumns, ...customFieldColumns, ...entityColumns];
  }, [fields, setEditTarget, setDeleteTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState,
    {
      columnVisibility: {
        maskId: hasPermissions([EPermission.ModuleBoligvaelger]),
      },
    }
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {mediaSequenceCreateTarget ? (
        <MediaSequenceCreate
          caseId={mediaSequenceCreateTarget.id}
          onClosed={() => setMediaSequenceCreateTarget(null)}
          isInitialOpen
        />
      ) : null}
      {editTarget ? (
        <CaseUpdate
          id={editTarget?.id}
          projectId={projectId}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'address'}
          getter={useGetCase}
          deleter={useDeleteCase}
        />
      ) : null}
    </>
  );
};
