import { useQuery } from '@tanstack/react-query';

import { TableQueryState } from 'components/Table/useTableQueryState';
import { CoreBaseConfiguration } from './BaseConfiguration';
import { AuditLogsApi } from './core';

const AuditLogApiKeys = {
  GET_FIELDS: 'auditlogs',
  GET_FIELD: 'auditlog',
};

const client = new AuditLogsApi(new CoreBaseConfiguration());

export const useGetAuditLogs = (state?: TableQueryState) => {
  return useQuery({
    queryKey: [AuditLogApiKeys.GET_FIELDS, state],
    queryFn: () =>
      client.auditLogsGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
  });
};

export const useGetAuditLog = (id?: number) => {
  return useQuery({
    queryKey: [AuditLogApiKeys.GET_FIELD, id],
    queryFn: () => client.auditLogsIdGet({ id: id ?? 0 }),
    enabled: id !== undefined,
  });
};
