import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { ProjectForm } from './ProjectForm';

interface ProjectCreateProps {
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const ProjectCreate = ({
  trigger,
  isInitialOpen,
  onClosed,
}: ProjectCreateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);
  const [title, setTitle] = useState<string>('Opret projekt');

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title={title}
        closeOnOutsideClick
        hideActionBar
      >
        <ProjectForm
          setTitle={setTitle}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
        />
      </ControlledModal>
    </>
  );
};
