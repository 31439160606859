import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { MediaSequenceBranchForm } from './MediaSequenceBranchForm';
import { useGetMediaSequenceBranch } from 'api/useMediaSequenceBranchesApi';

interface MediaSequenceBranchUpdateProps {
  id?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const MediaSequenceBranchUpdate = ({
  id,
  trigger,
  isInitialOpen,
  onClosed,
}: MediaSequenceBranchUpdateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data } = useGetMediaSequenceBranch(id);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  if (!data) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opdater format"
        closeOnOutsideClick
        hideActionBar
      >
        <MediaSequenceBranchForm
          targetBranch={data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
