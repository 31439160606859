import { useMutation, useQuery } from '@tanstack/react-query';

import { CoreBaseConfiguration } from './BaseConfiguration';
import { queryClient } from 'App';
import { toast } from 'react-toastify';
import { RoleCreateRequest, RolesApi, RoleUpdateRequest } from './core';

const RoleApiKeys = {
  GET_ROLES: 'roles',
  GET_ROLE: 'role',
};

const client = new RolesApi(new CoreBaseConfiguration());

export const useGetRoles = () => {
  return useQuery({
    queryKey: [RoleApiKeys.GET_ROLES],
    queryFn: () => client.rolesGet(),
  });
};

export const useGetRole = (id?: string) => {
  return useQuery({
    queryKey: [RoleApiKeys.GET_ROLE, id],
    queryFn: () => client.rolesIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateRole = () => {
  return useMutation({
    mutationFn: ({
      roleCreateRequest,
    }: {
      roleCreateRequest: RoleCreateRequest;
    }) => client.rolesPost({ roleCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [RoleApiKeys.GET_ROLES],
      });
      toast.success(`Rollen '${e.title}' er blevet oprettet`, {
        toastId: 'createRole',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke oprette rollen');
    },
  });
};

export const useUpdateRole = () => {
  return useMutation({
    mutationFn: ({
      id,
      roleUpdateRequest,
    }: {
      id: string;
      roleUpdateRequest: RoleUpdateRequest;
    }) => client.rolesIdPut({ id, roleUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [RoleApiKeys.GET_ROLES],
      });
      queryClient.invalidateQueries({
        queryKey: [RoleApiKeys.GET_ROLE, e.id],
      });
      toast.success(`Rollen '${e.title}' er blevet opdateret`, {
        toastId: 'updateRole',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke opdatere rollen');
    },
  });
};

export const useDeleteRole = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => client.rolesIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [RoleApiKeys.GET_ROLES],
      });
      queryClient.invalidateQueries({
        queryKey: [RoleApiKeys.GET_ROLE, e.id],
      });
      toast.success(`Rollen '${e.title}' er blevet slettet`, {
        toastId: 'deleteRole',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke slette rollen');
    },
  });
};
