import { useEffect, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface AutocompleteAddressProps {
  label: string;
  registration: () => UseFormRegisterReturn;
  formWatch: string;
  formSetValue: (value: string) => void;
  disabledInput?: boolean;
  readonlyInput?: boolean;
  suggestAddresses?: boolean;
  inputClassName?: string;
  onSuggestionSelectedFn?: (suggestion: AddressSuggestion | undefined) => void;
}

export type AddressSuggestion = {
  type: 'adresse' | 'vejnavn' | 'adgangsadresse';
  tekst: string;
  forslagstekst: string;
  data: {
    id?: string;
  };
};

export const AutocompleteAddress = ({
  label,
  registration,
  formWatch,
  formSetValue,
  onSuggestionSelectedFn,
  disabledInput = false,
  readonlyInput = false,
  suggestAddresses = true,
  inputClassName = 'input input-bordered',
}: AutocompleteAddressProps) => {
  const [debouncedName, setDebouncedName] = useState(formWatch);
  const [addressSuggestions, setAddressSuggestions] = useState<
    AddressSuggestion[]
  >([]);
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<AddressSuggestion | null>();

  useEffect(() => {
    onSuggestionSelectedFn?.(selectedSuggestion || undefined);
  }, [selectedSuggestion, onSuggestionSelectedFn]);

  useEffect(() => {
    if (!suggestAddresses) {
      setAddressSuggestions([]);
      return;
    }

    const handler = setTimeout(() => {
      setDebouncedName(formWatch);
    }, 50);

    // Cleanup function to clear the timeout if name changes again within the debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [formWatch, suggestAddresses]);

  useEffect(() => {
    const fetchAddressSuggestions = async () => {
      if (!debouncedName || debouncedName.trim() === '' || !suggestAddresses) {
        setAddressSuggestions([]);
        return;
      }

      try {
        const response = await fetch(
          `https://dawa.aws.dk/autocomplete?type=adresse&per_side=5&q=${encodeURIComponent(debouncedName)}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: AddressSuggestion[] = await response.json();
        setAddressSuggestions(data);
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
      }
    };

    fetchAddressSuggestions();
  }, [debouncedName, suggestAddresses]);

  useEffect(() => {
    if (!addressSuggestions || addressSuggestions.length === 0) {
      setSelectedSuggestion(null);
      return;
    }

    if (addressSuggestions.length === 1) {
      setSelectedSuggestion(addressSuggestions[0]);
      return;
    }

    const matchingSuggestion = addressSuggestions.find(
      (e) => e.forslagstekst === debouncedName
    );
    setSelectedSuggestion(matchingSuggestion || null);
  }, [addressSuggestions, debouncedName]);

  return (
    <>
      <div className="form-control">
        <label className="label">
          <span className="label-text">{label} </span>
        </label>
        <input
          {...registration()}
          type="text"
          disabled={disabledInput}
          readOnly={readonlyInput}
          className={inputClassName}
          autoComplete={suggestAddresses ? 'off' : 'on'}
        />
      </div>

      {suggestAddresses &&
      !disabledInput &&
      !readonlyInput &&
      addressSuggestions.length ? (
        <div className="form-control">
          <label className="label">
            <span className="label-text">Adresseforslag</span>
          </label>
          <ul className="list-none p-0 m-0">
            {addressSuggestions.map((suggestion) => (
              <li
                key={suggestion.data.id || suggestion.tekst}
                className={twMerge(
                  suggestion.data.id &&
                    suggestion.data.id === selectedSuggestion?.data.id &&
                    'bg-gray-200',
                  'p-2 border-b border-gray-200 cursor-pointer hover:bg-gray-200'
                )}
                onClick={() => {
                  formSetValue(suggestion.forslagstekst || suggestion.tekst);
                }}
              >
                {suggestion.forslagstekst || suggestion.tekst}{' '}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};
