import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useAuth } from 'auth/AuthProvider';
import { useGetOrganizationTickets } from 'api/useSessionsApi';
import { OrganizationSessionsTable } from './OrganizationSessionsTable';

export const OrganizationSessions = () => {
  const { user } = useAuth();
  const { state } = useTableQueryState();
  const { data: sessions, isFetching } = useGetOrganizationTickets(state);

  if (!user) return null;

  return (
    <Page
      title={user.organization.name}
      description={`Denne side viser de seneste sessioner for brugere tilknyttet ${user.organization.name}.`}
      breadcrumbs={[
        { name: user.organization.name },
        { name: 'Seneste sessioner' },
      ]}
    >
      <OrganizationSessionsTable
        data={sessions}
        isFetching={isFetching}
        queryState={state}
      />
    </Page>
  );
};
